/**
 * Checks if the user has the required permissions.
 * @param requiredPermissions - An array of required permissions or arrays of alternative permissions.
 * @param orgRoles - An array of org roles.
 * @returns A boolean indicating whether the user has the required permissions.
 */
export const checkPermissions = (requiredPermissions: (string | string[])[] | null, orgRoles: string[]): boolean => {
  if (!requiredPermissions) {
    return true; // If no required permissions are specified, assume all permissions are granted.
  }

  const userPermissions: Set<string> = new Set(orgRoles);
  return requiredPermissions.every((requiredPermission) => {
    if (Array.isArray(requiredPermission)) {
      return requiredPermission.some((permission) => userPermissions.has(permission));
    }
    return userPermissions.has(requiredPermission);
  });
};
