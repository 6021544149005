import React, { lazy } from 'react';
import { MessageDescriptor } from 'react-intl';
import messages from '../../messages';
import { RightType } from '@volvogroup-internal/sof-authorization-client/openapi';

const Login = lazy((): any => import('../Login/Login'));
const SignUp = lazy((): any => import('../SignUp/SignUp'));
const SetPassword = lazy((): any => import('../Login/SetPassword'));
const ForgotPassword = lazy((): any => import('../Login/ForgotPassword/ForgotPassword'));
const ChangePassword = lazy((): any => import('../Login/ChangePassword/ChangePassword'));
const SelectOrganisation = lazy((): any => import('../Login/SelectOrganisation'));

const TransportOrders = lazy((): any => import('../TransportOrder/TransportOrderContainer'));
const TransportOrderDetails = lazy((): any => import('../TransportOrder/Details/TransportOrderContent'));

const Transports = lazy((): any => import('../Transport/TransportContainer'));
const TransportDetails = lazy((): any => import('../Transport/Details/TransportContent'));

const RoutesView = lazy((): any => import('../Route/RouteContainer'));
const RouteDetails = lazy((): any => import('../Route/Details/RouteContent'));

//const CarrierReports = lazy((): any => import('../Reports/Reports/CarrierReportsContainer'));
//const TransportBuyerReports = lazy((): any => import('../Reports/Reports/TransportBuyerReportsContainer'));

// const CarrierTransportOrderReportsOverview = lazy(
//   (): any => import('../Reports/TransportOrderReports/CarrierTransportOrderReportsContainer'),
// );
const TransportBuyerTransportOrderReportsOverview = lazy(
  (): any => import('../Reports/TransportOrderReports/TransportBuyerTransportOrderReportsContainer'),
);
const CarrierTransportOrderReportDetails = lazy(
  (): any => import('../Reports/TransportOrderReports/Details/CarrierTransportOrderReportDetailsContainer'),
);
const TransportBuyerTransportOrderReportDetails = lazy(
  (): any => import('../Reports/TransportOrderReports/Details/TransportBuyerTransportOrderReportDetailsContainer'),
);

// const TransportBuyerTransportReportsOverview = lazy(
//   () => import('../Reports/TransportReports/TransportBuyerTransportReportsContainer'),
// );
const TransportBuyerTransportReportDetails = lazy(
  () => import('../Reports/TransportReports/Details/TransportBuyerTransportReportDetailsContainer'),
);
// const CarrierTransportReportsOverview = lazy(
//   () => import('../Reports/TransportReports/CarrierTransportReportsContainer'),
// );
const CarrierTransportReportDetails = lazy(
  () => import('../Reports/TransportReports/Details/CarrierTransportReportDetailsContainer'),
);

const Vehicles = lazy((): any => import('../Vehicle/VehicleContainer'));
const VehicleDetails = lazy((): any => import('../Vehicle/VehicleDetails/VehicleDetails'));
const VehicleCreator = lazy((): any => import('../Vehicle/NewVehicle/VehicleCreator'));
const TrainForm = lazy((): any => import('../Vehicle/NewVehicle/Train/TrainFormContainer'));
const VehicleImport = lazy((): any => import('../Vehicle/Import/VehicleImport'));
const ConnectWithDaimlerFleetboard = lazy(
  (): any => import('../Vehicle/NewVehicle/Daimler/DaimlerFleetboardVehicleCreator'),
);

const ConnectWithRfms = lazy((): any => import('../Vehicle/NewVehicle/Rfms/RfmsVehicleCreator'));
// const ConnectWithGeotab = lazy((): any => import('../Vehicle/NewVehicle/Geotab/GeotabVehicleCreator'));
const ConnectWithHighMobility = lazy(
  (): any => import('../Vehicle/NewVehicle/HighMobility/HighMobilityVehicleCreator'),
);
const VehicleAdded = lazy((): any => import('../Vehicle/NewVehicle/VehicleAdded'));

const SustainabilityScores = lazy((): any => import('../SustainabilityScore/SustainabilityScoreContainer'));

const Dashboard = lazy((): any => import('../Dashboard/DashboardContainer'));

const B2BIntegration = lazy((): any => import('../B2BIntegration/B2BIntegrationContainer'));
const B2BIntegrationForCarrier = lazy((): any => import('../B2BIntegration/B2BIntegrationContainerForCarrier'));

const Carriers = lazy((): any => import('../Carrier/CarriersContainer'));
const InviteCarrier = lazy((): any => import('../Carrier/Invite/InviteCarrier'));
const CarrierDetails = lazy((): any => import('../Carrier/Details/CarrierDetails'));
const CarrierInviteDetails = lazy((): any => import('../Carrier/Details/CarrierInviteDetails'));
const CarrierReceivedInviteDetails = lazy((): any => import('../Carrier/Details/CarrierReceivedInviteDetails'));

const Merchants = lazy((): any => import('../Merchant/MerchantsContainer'));
const InviteMerchant = lazy((): any => import('../Merchant/Invite/InviteMerchant'));
const MerchantDetails = lazy((): any => import('../Merchant/Details/MerchantDetails'));
const MerchantInviteDetails = lazy((): any => import('../Merchant/Details/MerchantInviteDetails'));
const MerchantReceivedInviteDetails = lazy((): any => import('../Merchant/Details/MerchantReceivedInviteDetails'));

const Users = lazy((): any => import('../User/UserContainer'));
const UserForm = lazy((): any => import('../User/UserForm'));
const UserDetails = lazy((): any => import('../User/Details/UserContent'));
const UserEdit = lazy((): any => import('../User/Edit/UserEdit'));

const MyAccount = lazy((): any => import('../User/MyAccount/MyAccount'));
const HelpAndSupport = lazy((): any => import('../HelpAndSupport/HelpAndSupport'));
const Documentation = lazy((): any => import('../HelpAndSupport/Documentation'));
const About = lazy((): any => import('../HelpAndSupport/About'));
const ConfirmOrgAccount = lazy((): any => import('../ConfirmOrgAccount/ConfirmOrgAccount'));

export enum TeraloRoute {
  DASHBOARD,
  TRANSPORT_ORDERS,
  TRANSPORTS,
  ROUTES,
  VEHICLES,
  CARRIERS,
  MERCHANTS,
  SUSTAINABILITY_SCORE,
  LOGIN,
  SIGNUP,
  B2B_INTEGRATION,
  B2B_INTEGRATION_CARRIER,
  SET_PASSWORD,
  SELECT_ORGANISATION,
  USERS,
  ADD_USER,
  EDIT_USER,
  MY_ACCOUNT,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  TRANSPORT_ORDER_REPORT,
  HELP_AND_SUPPORT,
  DOCUMENTATION,
  ABOUT,
  CONFIRMORGACCOUNT,
}

export enum RouteIcons {
  START = 'home',
  DASHBOARD = 'dashboard',
  VEHICLES = 'truckssideoutline',
  CARRIERS = 'boxes',
  MERCHANTS = 'shoppingcart',
  ROUTES = 'trip',
  REPORTS = 'report',
  SETTINGS = 'settings',
  TRANSPORTS = 'driver',
  MY_ACCOUNT = 'user',
  USERS = 'usergroup',
  HELP_AND_SUPPORT = 'info',
}

export const routesMap: Record<TeraloRoute, string> = {
  [TeraloRoute.LOGIN]: '/login',
  [TeraloRoute.SIGNUP]: '/signup',
  [TeraloRoute.SELECT_ORGANISATION]: '/select-organisation',
  [TeraloRoute.DASHBOARD]: `/dashboard`,
  [TeraloRoute.TRANSPORT_ORDERS]: `/transportorders`,
  [TeraloRoute.TRANSPORTS]: `/transports`,
  [TeraloRoute.ROUTES]: `/routes`,
  [TeraloRoute.VEHICLES]: `/vehicles`,
  [TeraloRoute.CARRIERS]: `/carriers`,
  [TeraloRoute.MERCHANTS]: `/merchants`,
  [TeraloRoute.SUSTAINABILITY_SCORE]: `/sustainability-score`,
  [TeraloRoute.B2B_INTEGRATION]: `/b2b-integration`,
  [TeraloRoute.B2B_INTEGRATION_CARRIER]: `/b2b-integration-carrier`,
  [TeraloRoute.SET_PASSWORD]: `/set-password`,
  [TeraloRoute.USERS]: `/admin/users`,
  [TeraloRoute.ADD_USER]: `/admin/users/new-user`,
  [TeraloRoute.EDIT_USER]: `/admin/users/edit-user`,
  [TeraloRoute.MY_ACCOUNT]: `/my-account`,
  [TeraloRoute.FORGOT_PASSWORD]: '/forgot-password',
  [TeraloRoute.CHANGE_PASSWORD]: '/change-password',
  [TeraloRoute.TRANSPORT_ORDER_REPORT]: '/transport-buyer/reports/overview',
  [TeraloRoute.HELP_AND_SUPPORT]: '/helpandsupport',
  [TeraloRoute.DOCUMENTATION]: '/documentation',
  [TeraloRoute.ABOUT]: '/about',
  [TeraloRoute.CONFIRMORGACCOUNT]: '/confirmorgaccount',
};

export interface NavigationItem {
  subItems: RouteConfig[];
  icon: RouteIcons;
  title: MessageDescriptor;
}

export interface RouteConfig {
  hideFromNavigation?: boolean;
  title: MessageDescriptor;
  path: string | string[];
  component: React.LazyExoticComponent<React.ComponentType<any>>;
  requiredPermissions: string[] | null;
  icon?: RouteIcons;
}

export type RouteElement = NavigationItem | RouteConfig;

export const routesList: RouteElement[] = [
  // ***** Login, signup and set Password *****
  {
    title: messages.signUp,
    path: routesMap[TeraloRoute.SIGNUP],
    component: SignUp,
    requiredPermissions: null,
    hideFromNavigation: true,
  },
  {
    title: messages.support,
    path: routesMap[TeraloRoute.LOGIN],
    component: Login,
    requiredPermissions: null,
    hideFromNavigation: true,
  },
  {
    title: messages.support,
    path: routesMap[TeraloRoute.SELECT_ORGANISATION],
    component: SelectOrganisation,
    requiredPermissions: null,
    hideFromNavigation: true,
  },
  {
    title: messages.setPassword,
    path: routesMap[TeraloRoute.SET_PASSWORD],
    component: SetPassword,
    requiredPermissions: null,
    hideFromNavigation: true,
  },
  {
    title: messages.forgot_password,
    path: routesMap[TeraloRoute.FORGOT_PASSWORD],
    component: ForgotPassword,
    requiredPermissions: null,
    hideFromNavigation: true,
  },
  {
    title: messages.change_password,
    path: routesMap[TeraloRoute.CHANGE_PASSWORD],
    component: ChangePassword,
    requiredPermissions: null,
    hideFromNavigation: true,
  },
  {
    title: messages.confirm_org_account,
    path: routesMap[TeraloRoute.CONFIRMORGACCOUNT],
    component: ConfirmOrgAccount,
    requiredPermissions: null,
    hideFromNavigation: true,
  },

  // ***** Start and Dashboard *****
  {
    title: messages.start,
    path: '/dashboard',
    component: Dashboard,
    requiredPermissions: null,
    icon: RouteIcons.START,
    hideFromNavigation: true,
  },
  {
    title: messages.dashboard,
    component: Dashboard,
    path: '/dashboard',
    requiredPermissions: [RightType.GET_CARRIER_DASHBOARD_VIEW],
    icon: RouteIcons.DASHBOARD,
  },
  {
    title: messages.dashboard,
    component: Dashboard,
    path: '/dashboard',
    requiredPermissions: [RightType.GET_MERCHANT_DASHBOARD_VIEW],
    icon: RouteIcons.DASHBOARD,
  },
  {
    title: messages.dashboard,
    component: Dashboard,
    path: '/dashboard',
    requiredPermissions: [RightType.GET_SUBCARRIER_DASHBOARD_VIEW],
    icon: RouteIcons.DASHBOARD,
  },
  // ***** Transport Orders *****
  {
    title: messages.transport_orders,
    path: 'transportorders',
    component: TransportOrders,
    requiredPermissions: [RightType.GET_TRANSPORT_ORDER_VIEW, RightType.GET_TRANSPORT_ORDER],
    icon: RouteIcons.REPORTS,
  },
  {
    title: messages.transport_orders,
    path: `transportorders/:transportOrderId`,
    component: TransportOrderDetails,
    requiredPermissions: [RightType.GET_TRANSPORT_ORDER_VIEW, RightType.GET_TRANSPORT_ORDER],
    hideFromNavigation: true,
  },

  // ***** Transports *****
  {
    title: messages.transports,
    path: 'transports',
    component: Transports,
    requiredPermissions: [RightType.GET_TRANSPORT_VIEW, RightType.GET_TRANSPORT],
    icon: RouteIcons.TRANSPORTS,
  },
  {
    title: messages.transports,
    path: `transports/:transportId`,
    component: TransportDetails,
    hideFromNavigation: true,
    requiredPermissions: [RightType.GET_TRANSPORT_VIEW, RightType.GET_TRANSPORT],
  },

  // ***** Routes *****
  {
    title: messages.routes,
    path: 'routes',
    component: RoutesView,
    requiredPermissions: [RightType.GET_ROUTE_VIEW, RightType.GET_ROUTE],
    icon: RouteIcons.ROUTES,
  },
  {
    title: messages.routes,
    path: `routes/:routeId`,
    component: RouteDetails,
    hideFromNavigation: true,
    requiredPermissions: [RightType.GET_ROUTE_VIEW, RightType.GET_ROUTE],
  },

  // ***** Vehicles *****
  {
    title: messages.vehicles,
    path: 'vehicles',
    component: Vehicles,
    requiredPermissions: [RightType.GET_VEHICLE_VIEW, RightType.GET_VEHICLE],
    icon: RouteIcons.VEHICLES,
  },
  {
    title: messages.vehicles,
    path: `vehicles/:vehiclePid`,
    component: VehicleDetails,
    hideFromNavigation: true,
    requiredPermissions: [RightType.GET_VEHICLE_VIEW, RightType.GET_VEHICLE],
  },
  {
    title: messages.vehicles,
    path: `vehicles/new`,
    component: VehicleCreator,
    hideFromNavigation: true,
    requiredPermissions: [RightType.GET_VEHICLE_VIEW, RightType.GET_VEHICLE, RightType.CRUD_VEHICLE],
  },
  {
    title: messages.vehicles,
    path: `vehicles/new/train`,
    component: TrainForm,
    hideFromNavigation: true,
    requiredPermissions: [RightType.GET_VEHICLE_VIEW, RightType.GET_VEHICLE, RightType.CRUD_VEHICLE],
  },
  {
    title: messages.vehicles,
    path: `vehicles/added/:vehiclePid`,
    component: VehicleAdded,
    hideFromNavigation: true,
    requiredPermissions: [RightType.GET_VEHICLE_VIEW, RightType.GET_VEHICLE, RightType.CRUD_VEHICLE],
  },
  {
    title: messages.vehicles,
    path: `vehicles/connect/rfms`,
    component: ConnectWithRfms,
    hideFromNavigation: true,
    requiredPermissions: [
      RightType.GET_VEHICLE_VIEW,
      RightType.GET_VEHICLE,
      RightType.CRUD_VEHICLE,
      RightType.RFMS_CONFIG,
    ],
  },
  {
    title: messages.vehicles,
    path: `vehicles/connect/fleetboard`,
    component: ConnectWithDaimlerFleetboard,
    hideFromNavigation: true,
    requiredPermissions: [
      RightType.GET_VEHICLE_VIEW,
      RightType.GET_VEHICLE,
      RightType.CRUD_VEHICLE,
      RightType.DAIMLER_CONFIG,
    ],
  },
  {
    title: messages.vehicles,
    path: `vehicles/connect/highmobility`,
    component: ConnectWithHighMobility,
    hideFromNavigation: true,
    requiredPermissions: [
      RightType.GET_VEHICLE_VIEW,
      RightType.GET_VEHICLE,
      RightType.CRUD_VEHICLE,
      RightType.HIGHMOB_CONFIG,
    ],
  },
  /*{ // TODO Uncomment when implemented
    title: messages.vehicles,
    path: `vehicles/connect/geotab`,
    component: ConnectWithGeotab,
    hideFromNavigation: true,
    requiredPermissions: ['GET_VEHICLE_VIEW', 'GET_VEHICLE', 'CRUD_VEHICLE'],
  },*/
  {
    title: messages.vehicles,
    path: `vehicles/import/file`,
    component: VehicleImport,
    hideFromNavigation: true,
    requiredPermissions: [RightType.GET_VEHICLE_VIEW, RightType.GET_VEHICLE, RightType.CRUD_VEHICLE],
  },
  // ***** Carriers *****
  {
    title: messages.carriers,
    path: ['carriers', `carriers/tab/:tab`, `carrier/tab/:tab`],
    component: Carriers,
    requiredPermissions: [RightType.GET_CARRIER_VIEW, RightType.GET_ORG],
    icon: RouteIcons.CARRIERS,
  },
  {
    title: messages.carriers,
    path: ['/carriers/:carrierId', '/carriers/:carrierId/:relation'],
    component: CarrierDetails,
    hideFromNavigation: true,
    requiredPermissions: [RightType.GET_CARRIER_VIEW, RightType.GET_ORG],
  },
  {
    title: messages.carriers,
    path: `carriers/new-invite`,
    component: InviteCarrier,
    hideFromNavigation: true,
    requiredPermissions: [RightType.GET_CARRIER_VIEW, RightType.INVITE_ORG_TO_ORG],
  },

  {
    title: messages.carriers,
    path: `carriers/invite/:inviteId`,
    component: CarrierInviteDetails,
    hideFromNavigation: true,
    requiredPermissions: [RightType.GET_CARRIER_VIEW, RightType.INVITE_ORG_TO_ORG],
  },
  {
    title: messages.carriers,
    path: `carriers/received-invite/:inviteId`,
    component: CarrierReceivedInviteDetails,
    hideFromNavigation: true,
    requiredPermissions: [RightType.GET_CARRIER_VIEW, RightType.INVITE_ORG_TO_ORG],
  },

  // ***** Merchants *****
  {
    title: messages.merchants,
    path: ['merchants', `merchants/tab/:tab`, `merchant/tab/:tab`],
    component: Merchants,
    requiredPermissions: [RightType.GET_MERCHANT_VIEW, RightType.GET_ORG],
    icon: RouteIcons.MERCHANTS,
  },
  {
    title: messages.merchants,
    path: ['/merchants/:merchantId', '/merchants/:merchantId/:relation'],
    component: MerchantDetails,
    hideFromNavigation: true,
    requiredPermissions: [RightType.GET_MERCHANT_VIEW, RightType.GET_ORG],
  },
  {
    title: messages.merchants,
    path: `merchants/new-invite`,
    component: InviteMerchant,
    hideFromNavigation: true,
    requiredPermissions: [RightType.GET_MERCHANT_VIEW, RightType.INVITE_ORG_TO_ORG],
  },
  {
    title: messages.merchants,
    path: `merchants/invite/:inviteId`,
    component: MerchantInviteDetails,
    hideFromNavigation: true,
    requiredPermissions: [RightType.GET_MERCHANT_VIEW, RightType.INVITE_ORG_TO_ORG],
  },
  {
    title: messages.merchants,
    path: `merchants/received-invite/:inviteId`,
    component: MerchantReceivedInviteDetails,
    hideFromNavigation: true,
    requiredPermissions: [RightType.GET_MERCHANT_VIEW, RightType.INVITE_ORG_TO_ORG],
  },

  // ***** Reports *****
  {
    hideFromNavigation: true,
    title: messages.transporterCustomTransportReports,
    path: `/carrier/transport-reports/:pid`,
    component: CarrierTransportReportDetails,
    requiredPermissions: [RightType.GET_REPORT_VIEW],
  },
  {
    title: messages.transporterCustomReports,
    path: `/carrier/reports/:pid`,
    component: CarrierTransportOrderReportDetails,
    requiredPermissions: [RightType.GET_REPORT_VIEW],
    hideFromNavigation: true,
  },
  {
    hideFromNavigation: true,
    title: messages.transportBuyerCustomReports,
    path: `/transport-buyer/reports/:pid`, // TODO Update route from transport-buyer to merchant
    component: TransportBuyerTransportOrderReportDetails,
    requiredPermissions: [RightType.GET_REPORT_VIEW],
  },
  {
    hideFromNavigation: true,
    title: messages.transportBuyerCustomTransportReports,
    path: `/transport-buyer/transport-reports/:pid`, // TODO Update route from transport-buyer to merchant
    component: TransportBuyerTransportReportDetails,
    requiredPermissions: [RightType.GET_REPORT_VIEW],
  },

  {
    title: messages.reports,
    icon: RouteIcons.REPORTS,
    requiredPermissions: [RightType.GET_REPORT_VIEW],
    subItems: [
      // {
      //   title: messages.transportBuyerReports,
      //   path: '/transport-buyer/reports',
      //   component: TransportBuyerReports,
      //   requiredPermissions: ['GET_MERCHANT_REPORT', 'GET_REPORT_VIEW'],
      // },
      // {
      //   title: messages.transporterReports,
      //   path: `carrier/reports`,
      //   component: CarrierReports,
      //   requiredPermissions: ['GET_CARRIER_REPORT', 'GET_REPORT_VIEW'],
      // },
      {
        title: messages.transportBuyerCustomReports,
        path: `/transport-buyer/reports/overview`, // TODO Update route from transport-buyer to merchant
        component: TransportBuyerTransportOrderReportsOverview,
        requiredPermissions: [RightType.GET_TRANSPORT_ORDER_REPORT, RightType.GET_REPORT_VIEW],
      },
      // {
      //   title: messages.transporterCustomReports,
      //   path: `/carrier/reports/overview`,
      //   component: CarrierTransportOrderReportsOverview,
      //   requiredPermissions: ['GET_CARRIER_REPORT', 'GET_REPORT_VIEW'],
      // },
      // {
      //   title: messages.transporterCustomTransportReports,
      //   path: `/carrier/transport-reports/overview`,
      //   component: CarrierTransportReportsOverview,
      //   requiredPermissions: ['GET_CARRIER_TRANSPORT_REPORT', 'GET_REPORT_VIEW'],
      // },
      // {
      //   title: messages.transportBuyerCustomTransportReports,
      //   path: `/transport-buyer/transport-reports/overview`,
      //   component: TransportBuyerTransportReportsOverview,
      //   requiredPermissions: ['GET_MERCHANT_REPORT', 'GET_REPORT_VIEW'],
      // },
    ],
  },

  // ***** Settings *****
  {
    title: messages.settings,
    icon: RouteIcons.SETTINGS,
    requiredPermissions: [RightType.GET_SETTING_VIEW],
    subItems: [
      {
        title: messages.sustainability_score,
        path: '/sustainability-score',
        component: SustainabilityScores,
        requiredPermissions: [RightType.GET_SETTING_VIEW],
      },
      {
        title: messages.b2bIntegration,
        path: `b2b-integration`,
        component: B2BIntegration,
        requiredPermissions: [RightType.CRUD_ORG, RightType.CRUD_TRANSPORT_ORDER, RightType.GET_SETTING_VIEW],
      },
    ],
  },

  {
    title: messages.settings,
    icon: RouteIcons.SETTINGS,
    requiredPermissions: [RightType.GET_CO2E_WIDGET_CARRIERFREE],
    subItems: [
      {
        title: messages.b2bIntegration,
        path: `b2b-integration-carrier`,
        component: B2BIntegrationForCarrier,
        requiredPermissions: [RightType.GET_CO2E_WIDGET_CARRIERFREE],
      },
    ],
  },

  // ***** Admin users *****
  {
    title: messages.users,
    path: `admin/users`,
    component: Users,
    requiredPermissions: [RightType.GET_USER, RightType.GET_USER_VIEW],
    icon: RouteIcons.USERS,
  },
  {
    title: messages.users,
    path: `admin/users/:pid`,
    component: UserDetails,
    requiredPermissions: [RightType.GET_USER, RightType.GET_USER_VIEW],
    hideFromNavigation: true,
  },
  {
    title: messages.addUser,
    path: `admin/users/new-user`,
    component: UserForm,
    requiredPermissions: [RightType.CRUD_USER, RightType.GET_USER, RightType.GET_USER_VIEW],
    hideFromNavigation: true,
  },
  {
    title: messages.edit_user,
    path: `admin/users/edit-user/:pid`,
    component: UserEdit,
    requiredPermissions: [RightType.CRUD_USER, RightType.GET_USER, RightType.GET_USER_VIEW],
    hideFromNavigation: true,
  },

  // ***** Admin own account *****
  {
    title: messages.my_account,
    icon: RouteIcons.MY_ACCOUNT,
    path: `my-account`,
    component: MyAccount,
    requiredPermissions: [],
  },
  {
    title: messages.help,
    icon: RouteIcons.HELP_AND_SUPPORT,
    requiredPermissions: [],
    subItems: [
      {
        title: messages.help_and_support,
        path: '/helpandsupport',
        component: HelpAndSupport,
        requiredPermissions: null,
      },
      {
        title: messages.documentation,
        path: '/documentation',
        component: Documentation,
        requiredPermissions: null,
      },
      {
        title: messages.about,
        path: '/about',
        component: About,
        requiredPermissions: null,
      },
    ],
  },
];
