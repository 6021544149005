import { defineMessages } from 'react-intl';

export default defineMessages({
  addUser: {
    id: 'cargoenv.addUser.title',
    description: '',
    defaultMessage: 'Add user',
  },
  error_fetching_brands: {
    id: 'cargoenv.applicationContext.error_fetching_brands',
    description: '',
    defaultMessage: 'Error fetching brands',
  },
  error_fetching_org_roles: {
    id: 'cargoenv.applicationContext.error_fetching_org_roles',
    description: '',
    defaultMessage: 'Error fetching the org roles',
  },
  error_fetching_organizations: {
    id: 'cargoenv.applicationContext.error_fetching_organizations',
    description: '',
    defaultMessage: 'Error fetching organizations',
  },
  error_fetching_threshold_values: {
    id: 'cargoenv.applicationContext.error_fetching_threshold_values',
    description: '',
    defaultMessage: 'Error fetching threshold values',
  },
  error_fetching_transport_item_types: {
    id: 'cargoenv.applicationContext.error_fetching_transport_item_types',
    description: '',
    defaultMessage: 'Error fetching transport item types',
  },
  time_must_be_after: {
    id: 'cargoenv.autoValidatingForm.time_must_be_after',
    description: '',
    defaultMessage: 'Value must be after {timeReference}',
  },
  add_new_client: {
    id: 'cargoenv.b2b_integration.add_new_client',
    description: '',
    defaultMessage: 'Add new B2B client:',
  },
  api_name: {
    id: 'cargoenv.b2b_integration.api_name',
    description: '',
    defaultMessage: 'API Name',
  },
  api_names_updated_successfully: {
    id: 'cargoenv.b2b_integration.api_names_updated_successfully',
    description: '',
    defaultMessage: 'API name(s) updated successfully',
  },
  b2b_clients_header: {
    id: 'cargoenv.b2b_integration.b2b_clients_header',
    description: '',
    defaultMessage: 'B2B clients created for your organization:',
  },
  carrier_name: {
    id: 'cargoenv.b2b_integration.carrier_name',
    description: '',
    defaultMessage: 'Carrier Name',
  },
  client_created_successfully: {
    id: 'cargoenv.b2b_integration.client_created_successfully',
    description: '',
    defaultMessage: 'Client {clientId} was created successfully',
  },
  client_deleted_successfully: {
    id: 'cargoenv.b2b_integration.client_deleted_successfully',
    description: '',
    defaultMessage: 'Client {clientId} was deleted successfully',
  },
  client_id_header: {
    id: 'cargoenv.b2b_integration.client_id_header',
    description: '',
    defaultMessage: 'Client Id',
  },
  client_name_header: {
    id: 'cargoenv.b2b_integration.client_name_header',
    description: '',
    defaultMessage: 'Client Name',
  },
  client_secret_header: {
    id: 'cargoenv.b2b_integration.client_secret_header',
    description: '',
    defaultMessage: 'Client Secret',
  },
  connected_carriers_mapping_header: {
    id: 'cargoenv.b2b_integration.connected_carriers_mapping_header',
    description: '',
    defaultMessage: 'API names for connected carriers:',
  },
  connected_merchants_mapping_header: {
    id: 'cargoenv.b2b_integration.connected_merchants_mapping_header',
    description: '',
    defaultMessage: 'API names for connected merchants:',
  },
  delete_client_text: {
    id: 'cargoenv.b2b_integration.delete_client_text',
    description: '',
    defaultMessage: 'Do you really want to delete {clientName}?',
  },
  delete_client_title: {
    id: 'cargoenv.b2b_integration.delete_client_title',
    description: '',
    defaultMessage: 'Delete B2B client',
  },
  error_creating_client: {
    id: 'cargoenv.b2b_integration.error_creating_client',
    description: '',
    defaultMessage: 'Failed to create client',
  },
  error_deleting_client: {
    id: 'cargoenv.b2b_integration.error_deleting_client',
    description: '',
    defaultMessage: 'Failed to delete client',
  },
  error_getting_b2b_clients: {
    id: 'cargoenv.b2b_integration.error_getting_b2b_clients',
    description: '',
    defaultMessage: 'Error getting B2B clients',
  },
  error_updating_api_name: {
    id: 'cargoenv.b2b_integration.error_updating_api_name',
    description: '',
    defaultMessage: 'Failed to update API name for this carrier',
  },
  hide_secret: {
    id: 'cargoenv.b2b_integration.hide_secret',
    description: '',
    defaultMessage: 'Hide secret',
  },
  loading_b2b_clients: {
    id: 'cargoenv.b2b_integration.loading_b2b_clients',
    description: '',
    defaultMessage: 'Loading B2B clients...',
  },
  loading_connected_carriers: {
    id: 'cargoenv.b2b_integration.loading_connected_carriers',
    description: '',
    defaultMessage: 'Loading connected carriers...',
  },
  loading_connected_merchants: {
    id: 'cargoenv.b2b_integration.loading_connected_merchants',
    description: '',
    defaultMessage: 'Loading connected merchants...',
  },
  show_secret: {
    id: 'cargoenv.b2b_integration.show_secret',
    description: '',
    defaultMessage: 'Show secret',
  },
  b2bIntegration: {
    id: 'cargoenv.b2b_integration.title',
    description: '',
    defaultMessage: 'B2B Integration',
  },
  carrier_dashboard_description: {
    id: 'cargoenv.carrier_dashboard_description',
    description: '',
    defaultMessage: 'View and export information about CO2 Emissions and Energy Mix',
  },
  carrier_dashboard_label: {
    id: 'cargoenv.carrier_dashboard_label',
    description: '',
    defaultMessage: 'Widget Dashboard',
  },
  carrier_relation_admin_description: {
    id: 'cargoenv.carrier_relation_admin_description',
    description: '',
    defaultMessage: 'Invite, accept incoming invites and handle relations with carriers',
  },
  carrier_relation_admin_label: {
    id: 'cargoenv.carrier_relation_admin_label',
    description: '',
    defaultMessage: 'Carrier Relation Administrator',
  },
  carrier_relation_viewer_description: {
    id: 'cargoenv.carrier_relation_viewer_description',
    description: '',
    defaultMessage: 'View relations with carriers',
  },
  carrier_relation_viewer_label: {
    id: 'cargoenv.carrier_relation_viewer_label',
    description: '',
    defaultMessage: 'Carrier Relation Viewer',
  },
  connected_carriers: {
    id: 'cargoenv.carriers.connected_carriers',
    description: '',
    defaultMessage: 'Connected carriers',
  },
  delete_carrier_success: {
    id: 'cargoenv.carriers.delete_carrier_success',
    description: '',
    defaultMessage: 'Carrier connection(s) deleted successfully',
  },
  carriers_email: {
    id: 'cargoenv.carriers.email',
    description: '',
    defaultMessage: "Carrier's email",
  },
  error_delete_carrier: {
    id: 'cargoenv.carriers.error_delete_carrier',
    description: '',
    defaultMessage: 'Failed to delete carrier connection',
  },
  error_delete_invite_carrier: {
    id: 'cargoenv.carriers.error_delete_invite',
    description: '',
    defaultMessage: 'Failed to delete carrier invitation(s)',
  },
  error_fetch_carrier: {
    id: 'cargoenv.carriers.error_fetch_carrier',
    description: '',
    defaultMessage: 'Could not fetch carrier data',
  },
  invite_new_carrier: {
    id: 'cargoenv.carriers.invite_new_carrier',
    description: '',
    defaultMessage: 'Invite new carrier',
  },
  invite_new_carrier_text: {
    id: 'cargoenv.carriers.invite_new_carrier_text',
    description: '',
    defaultMessage:
      'Invite your carrier and let them know of Teralo. Once the carrier are signed up they can start adding vehicles to provide the correct transport data. ',
  },
  carriers_name: {
    id: 'cargoenv.carriers.name',
    description: '',
    defaultMessage: "Carrier's name",
  },
  no_carriers_message: {
    id: 'cargoenv.carriers.no_carriers_message',
    description: '',
    defaultMessage: 'There are no connected carriers. You can invite one by clicking on a button below',
  },
  no_invites_message_carrier: {
    id: 'cargoenv.carriers.no_invites_sent_message',
    description: '',
    defaultMessage: 'There are no invites sent. You can invite a carrier by clicking on the button below',
  },
  carriers_phoneNumber: {
    id: 'cargoenv.carriers.phoneNumber',
    description: '',
    defaultMessage: "Carrier's phone number",
  },
  remove_connection_dialog_carrier: {
    id: 'cargoenv.carriers.remove_connection_dialog',
    description: '',
    defaultMessage:
      'You are about to remove the connection to the carrier {carrierName}. You will no longer receive the transport data from your carrier. The previous transport data will not be removed, but you will not be able to filter on that specific carrier. You need to make a new connection with the carrier if you want to receive new transport data.',
  },
  remove_invite_title_carrier: {
    id: 'cargoenv.carriers.remove_invite_title',
    description: '',
    defaultMessage: 'Do you really want to remove the pending invite to this carrier?',
  },
  scenario_carrier_subcarrier: {
    id: 'cargoenv.carriers.scenario_carrier_subcarrier',
    description: '',
    defaultMessage: 'Carrier that would like to give assignments to a contracted carrier',
  },
  scenario_merchant_carrier: {
    id: 'cargoenv.carriers.scenario_merchant_carrier',
    description: '',
    defaultMessage: 'Merchant that would like to have my goods transported',
  },
  scenario_select_message: {
    id: 'cargoenv.carriers.scenario_select_message',
    description: '',
    defaultMessage: 'While doing this invitation to collaborate with my new connection I am acting as a:',
  },
  scenario_subcarrier_carrier: {
    id: 'cargoenv.carriers.scenario_subcarrier_carrier',
    description: '',
    defaultMessage: 'Contracted carrier that would like to carry out transportation for another carrier',
  },
  send_carrier_invitation_text: {
    id: 'cargoenv.carriers.send_invitation_text',
    description: '',
    defaultMessage:
      'Provide information about the carrier you want to invite to Teralo. We will then send an e-mail to them regarding sign up.',
  },
  change_password: {
    id: 'cargoenv.change_password.title',
    description: '',
    defaultMessage: 'Change password',
  },
  about: {
    id: 'cargoenv.common.about',
    description: '',
    defaultMessage: 'About',
  },
  accept_connection: {
    id: 'cargoenv.common.accept_connection',
    description: '',
    defaultMessage: 'Accept connection',
  },
  accept_connection_dialog: {
    id: 'cargoenv.common.accept_connection_dialog',
    description: '',
    defaultMessage:
      'You are about to connect to {name}. The organisation will get a notification about your connection.',
  },
  accept_connection_title: {
    id: 'cargoenv.common.accept_connection_title',
    description: '',
    defaultMessage: 'Do you want to connect to {name}?',
  },
  accept_selected: {
    id: 'cargoenv.common.accept_selected',
    description: '',
    defaultMessage: 'Accept selected',
  },
  accepted_invitations: {
    id: 'cargoenv.common.accepted_invitations',
    description: '',
    defaultMessage: 'Accepted invitations',
  },
  active_filters: {
    id: 'cargoenv.common.active_filters',
    description: '',
    defaultMessage: 'Active filters',
  },
  add: {
    id: 'cargoenv.common.add',
    description: '',
    defaultMessage: 'Add',
  },
  add_user: {
    id: 'cargoenv.common.add_user',
    description: '',
    defaultMessage: 'Add User',
  },
  additional_transport_order_items: {
    id: 'cargoenv.common.additional_transport_order_items',
    description: '',
    defaultMessage: 'Additional transport order items of #{transportOrderNumber}',
  },
  address: {
    id: 'cargoenv.common.address',
    description: '',
    defaultMessage: 'Address',
  },
  after_you_have_accepted: {
    id: 'cargoenv.common.after_you_have_accepted',
    description: '',
    defaultMessage:
      "After you have accepted the invite and didn't already have a user you will receive a temporary password in another email which you shall use for first login, you will then be prompted to create a new password.",
  },
  are_you_sure: {
    id: 'cargoenv.common.are_you_sure',
    description: '',
    defaultMessage: 'Are you sure?',
  },
  average: {
    id: 'cargoenv.common.average',
    description: '',
    defaultMessage: 'Average',
  },
  back: {
    id: 'cargoenv.common.back',
    description: '',
    defaultMessage: 'Back',
  },
  billing_address: {
    id: 'cargoenv.common.billing_address',
    description: '',
    defaultMessage: 'Billing address',
  },
  browse_file: {
    id: 'cargoenv.common.browse_file',
    description: '',
    defaultMessage: 'Browse file',
  },
  cancel: {
    id: 'cargoenv.common.cancel',
    description: '',
    defaultMessage: 'Cancel',
  },
  carrier: {
    id: 'cargoenv.common.carrier',
    description: '',
    defaultMessage: 'Carrier',
  },
  carriers: {
    id: 'cargoenv.common.carriers',
    description: '',
    defaultMessage: 'Carriers',
  },
  choose_this: {
    id: 'cargoenv.common.choose_this',
    description: '',
    defaultMessage: 'Choose this',
  },
  choose_this_option: {
    id: 'cargoenv.common.choose_this_option',
    description: '',
    defaultMessage: 'Choose this',
  },
  city: {
    id: 'cargoenv.common.city',
    description: '',
    defaultMessage: 'City',
  },
  close: {
    id: 'cargoenv.common.close',
    description: '',
    defaultMessage: 'Close',
  },
  co2e: {
    id: 'cargoenv.common.co2e',
    description: '',
    defaultMessage: 'CO₂e',
  },
  co2e_emission: {
    id: 'cargoenv.common.co2e_emission',
    description: '',
    defaultMessage: 'CO₂e Emission',
  },
  co2e_emissions: {
    id: 'cargoenv.common.co2e_emissions',
    description: '',
    defaultMessage: 'CO₂e Emissions',
  },
  co2e_emissions_from_transport: {
    id: 'cargoenv.common.co2e_emissions_from_transport',
    description: '',
    defaultMessage: 'CO₂e emissions from transport',
  },
  co2e_emissions_per_km: {
    id: 'cargoenv.common.co2e_emissions_per_km',
    description: '',
    defaultMessage: 'CO₂e Emissions/km',
  },
  co2e_emissions_per_tkm: {
    id: 'cargoenv.common.co2e_emissions_per_tkm',
    description: '',
    defaultMessage: 'CO₂e Emissions/tonKm',
  },
  co2e_ttw: {
    id: 'cargoenv.common.co2e_ttw',
    description: '',
    defaultMessage: 'CO₂e (TTW)',
  },
  co2e_wtt: {
    id: 'cargoenv.common.co2e_wtt',
    description: '',
    defaultMessage: 'CO₂e (WTT)',
  },
  company: {
    id: 'cargoenv.common.company',
    description: '',
    defaultMessage: 'Company',
  },
  company_info: {
    id: 'cargoenv.common.company_information',
    description: '',
    defaultMessage: 'Company information',
  },
  company_name: {
    id: 'cargoenv.common.company_name',
    description: '',
    defaultMessage: 'Company name',
  },
  complete: {
    id: 'cargoenv.common.complete',
    description: '',
    defaultMessage: 'Complete',
  },
  confirm: {
    id: 'cargoenv.common.confirm',
    description: '',
    defaultMessage: 'Confirm',
  },
  confirm_org_account: {
    id: 'cargoenv.common.confirm_org_account',
    description: '',
    defaultMessage: 'Confirm organization account',
  },
  confirm_selected: {
    id: 'cargoenv.common.confirm_selected',
    description: '',
    defaultMessage: 'Confirm selected',
  },
  confirm_termsOfUse: {
    id: 'cargoenv.common.confirm_termsOfUse',
    description: '',
    defaultMessage: 'Confirm terms of use',
  },
  connected_vehicle: {
    id: 'cargoenv.common.connected_vehicle',
    description: '',
    defaultMessage: 'Connected vehicle',
  },
  connected_vehicles: {
    id: 'cargoenv.common.connected_vehicles',
    description: '',
    defaultMessage: 'Connected vehicles',
  },
  connection_accepted: {
    id: 'cargoenv.common.connection_accepted',
    description: '',
    defaultMessage: 'Connection was accepted',
  },
  connection_accepted_count: {
    id: 'cargoenv.common.connection_accepted_count',
    description: '',
    defaultMessage: '{count, plural, one {Connection was} other {Connections were}} accepted',
  },
  telematic_status_activating: {
    id: 'cargoenv.common.connectionStatuses.activatating_label',
    description: '',
    defaultMessage: 'Activating',
  },
  telematic_status_activated: {
    id: 'cargoenv.common.connectionStatuses.activated_label',
    description: '',
    defaultMessage: 'Activated',
  },
  telematic_status_deactivated: {
    id: 'cargoenv.common.connectionStatuses.deactivated_label',
    description: '',
    defaultMessage: 'Deactivated',
  },
  telematic_status_error: {
    id: 'cargoenv.common.connectionStatuses.error_label',
    description: '',
    defaultMessage: 'Error',
  },
  telematic_status_registered: {
    id: 'cargoenv.common.connectionStatuses.registered_label',
    description: '',
    defaultMessage: 'Registered',
  },
  contact: {
    id: 'cargoenv.common.contact',
    description: '',
    defaultMessage: 'Contact',
  },
  contact_person: {
    id: 'cargoenv.common.contact_person',
    description: '',
    defaultMessage: 'Contact person',
  },
  continue: {
    id: 'cargoenv.common.continue',
    description: '',
    defaultMessage: 'Continue',
  },
  copy_link: {
    id: 'cargoenv.common.copy_link',
    description: '',
    defaultMessage: 'Copy invite link',
  },
  france_label: {
    id: 'cargoenv.common.countries.france_label',
    description: '',
    defaultMessage: 'France',
  },
  sweden_label: {
    id: 'cargoenv.common.countries.sweden_label',
    description: '',
    defaultMessage: 'Sweden',
  },
  country: {
    id: 'cargoenv.common.country',
    description: '',
    defaultMessage: 'Country',
  },
  csv_upload: {
    id: 'cargoenv.common.csv_upload',
    description: '',
    defaultMessage: 'Only CSV with semicolon separator are accepted.',
  },
  date: {
    id: 'cargoenv.common.date',
    description: '',
    defaultMessage: 'Date',
  },
  decline_connection: {
    id: 'cargoenv.common.decline_connection',
    description: '',
    defaultMessage: 'Decline connection',
  },
  decline_connection_dialog: {
    id: 'cargoenv.common.decline_connection_dialog',
    description: '',
    defaultMessage: 'You are about to decline the connection to {name}.',
  },
  decline_connection_title: {
    id: 'cargoenv.common.decline_connection_title',
    description: '',
    defaultMessage: 'Do you want to decline the connection to {name}?',
  },
  decline_selected: {
    id: 'cargoenv.common.decline_selected',
    description: '',
    defaultMessage: 'Decline selected',
  },
  delete: {
    id: 'cargoenv.common.delete',
    description: '',
    defaultMessage: 'Delete',
  },
  delete_invite_success: {
    id: 'cargoenv.common.delete_invite_success',
    description: '',
    defaultMessage: 'Invitation(s) deleted successfully',
  },
  details: {
    id: 'cargoenv.common.details',
    description: '',
    defaultMessage: 'Details',
  },
  discard_changes: {
    id: 'cargoenv.common.discard_changes',
    description: '',
    defaultMessage: 'Discard changes',
  },
  distance: {
    id: 'cargoenv.common.distance',
    description: '',
    defaultMessage: 'Distance',
  },
  do_you_want_to_continue: {
    id: 'cargoenv.common.do_you_want_to_continue',
    description: '',
    defaultMessage: 'Do you want to continue?',
  },
  documentation: {
    id: 'cargoenv.common.documentation',
    description: '',
    defaultMessage: 'Documentation',
  },
  done: {
    id: 'cargoenv.common.done',
    description: '',
    defaultMessage: 'Done',
  },
  edit: {
    id: 'cargoenv.common.edit',
    description: '',
    defaultMessage: 'Edit',
  },
  email: {
    id: 'cargoenv.common.email',
    description: '',
    defaultMessage: 'E-mail:',
  },
  error_accept_connection: {
    id: 'cargoenv.common.error_accept_connection',
    description: '',
    defaultMessage: 'Failed to accept {count, plural, one {the connection} other {connections}}',
  },
  error_claiming_invite: {
    id: 'cargoenv.common.error_claiming_invite',
    description: '',
    defaultMessage: 'Error claiming invite',
  },
  error_delete_invite: {
    id: 'cargoenv.common.error_delete_invite',
    description: '',
    defaultMessage: 'Failed to delete invitation(s)',
  },
  error_fetch_invite: {
    id: 'cargoenv.common.error_fetch_invite',
    description: '',
    defaultMessage: 'Could not fetch invite data',
  },
  error_fetching_transports: {
    id: 'cargoenv.common.error_fetching_transports',
    description: '',
    defaultMessage: 'Failed to fetch transports...',
  },
  error_getting_assigned_transport_orders: {
    id: 'cargoenv.common.error_getting_assigned_transport_orders',
    description: '',
    defaultMessage: 'Error getting assigned transport orders',
  },
  error_organization_not_active: {
    id: 'cargoenv.common.error_organization_not_active',
    description: '',
    defaultMessage: 'Your organization is not active, please contact Teralo support.',
  },
  error_resend_invite: {
    id: 'cargoenv.common.error_resend_invite',
    description: '',
    defaultMessage: 'Could not resend the invite',
  },
  error_storing_user_context: {
    id: 'cargoenv.common.error_storing_user_context',
    description: '',
    defaultMessage: 'Error storing user context',
  },
  expired: {
    id: 'cargoenv.common.expired',
    description: '',
    defaultMessage: 'Expired',
  },
  expired_invite_alert: {
    id: 'cargoenv.common.expired_invite_alert',
    description: '',
    defaultMessage:
      'This invitation has expired. Please send invite again if you still want to invite this organisation.',
  },
  failed_fetch_invite: {
    id: 'cargoenv.common.failed_fetch_invite',
    description: '',
    defaultMessage: 'Failed to fetch invitation',
  },
  failed_to_send_invite: {
    id: 'cargoenv.common.failed_to_send_invite',
    description: '',
    defaultMessage: 'Failed to send invite',
  },
  filter_by: {
    id: 'cargoenv.common.filter_by',
    description: '',
    defaultMessage: 'Filter by',
  },
  filters: {
    id: 'cargoenv.common.filters',
    description: '',
    defaultMessage: 'Filters',
  },
  first_name: {
    id: 'cargoenv.common.first_name',
    description: '',
    defaultMessage: 'First name',
  },
  fleetname: {
    id: 'cargoenv.common.fleetname',
    description: '',
    defaultMessage: 'Fleet name',
  },
  from: {
    id: 'cargoenv.common.from',
    description: '',
    defaultMessage: 'From',
  },
  bio_lng_label: {
    id: 'cargoenv.common.fueltypes.bio_lng_label',
    description: '',
    defaultMessage: 'BioLng',
  },
  cng_label: {
    id: 'cargoenv.common.fueltypes.cng_label',
    description: '',
    defaultMessage: 'CNG',
  },
  diesel_label: {
    id: 'cargoenv.common.fueltypes.diesel_label',
    description: '',
    defaultMessage: 'Diesel',
  },
  electricity_label: {
    id: 'cargoenv.common.fueltypes.electricity_label',
    description: '',
    defaultMessage: 'Electricity',
  },
  gasoline_label: {
    id: 'cargoenv.common.fueltypes.gasoline_label',
    description: '',
    defaultMessage: 'Gasoline',
  },
  hydrogen_label: {
    id: 'cargoenv.common.fueltypes.hydrogen_label',
    description: '',
    defaultMessage: 'Hydrogen',
  },
  lng_label: {
    id: 'cargoenv.common.fueltypes.lng_label',
    description: '',
    defaultMessage: 'LNG',
  },
  other_label: {
    id: 'cargoenv.common.fueltypes.other_label',
    description: '',
    defaultMessage: 'Other',
  },
  g: {
    id: 'cargoenv.common.g',
    description: '',
    defaultMessage: 'g',
  },
  go_to_startpage: {
    id: 'cargoenv.common.go_to_start_page',
    description: '',
    defaultMessage: 'Go to startpage',
  },
  goods: {
    id: 'cargoenv.common.goods',
    description: '',
    defaultMessage: 'Goods',
  },
  goods_volume_l: {
    id: 'cargoenv.common.goods_volume_l',
    description: '',
    defaultMessage: 'Goods volume (l)',
  },
  goods_weight: {
    id: 'cargoenv.common.goods_weight_title',
    description: '',
    defaultMessage: 'Goods Weight',
  },
  hello_from_teralo: {
    id: 'cargoenv.common.hello_from_teralo',
    description: '',
    defaultMessage: 'Hello from Teralo!',
  },
  help: {
    id: 'cargoenv.common.help',
    description: '',
    defaultMessage: 'Help',
  },
  home: {
    id: 'cargoenv.common.home',
    description: '',
    defaultMessage: 'Home',
  },
  import: {
    id: 'cargoenv.common.import',
    description: '',
    defaultMessage: 'Import',
  },
  incomplete: {
    id: 'cargoenv.common.incomplete',
    description: '',
    defaultMessage: 'Incomplete',
  },
  index: {
    id: 'cargoenv.common.index',
    description: '',
    defaultMessage: 'Index',
  },
  invitations: {
    id: 'cargoenv.common.invitations',
    description: '',
    defaultMessage: 'Invitations',
  },
  invite_accepted: {
    id: 'cargoenv.common.invite_accepted',
    description: '',
    defaultMessage: 'Invite accepted',
  },
  invite_carrier: {
    id: 'cargoenv.common.invite_carrier',
    description: '',
    defaultMessage: 'Invite carrier',
  },
  invite_carrier_title: {
    id: 'cargoenv.common.invite_carrier_title',
    description: '',
    defaultMessage: 'Invite Carrier',
  },
  invite_merchant: {
    id: 'cargoenv.common.invite_merchant',
    description: '',
    defaultMessage: 'Invite merchant',
  },
  invite_merchant_title: {
    id: 'cargoenv.common.invite_merchant_title',
    description: '',
    defaultMessage: 'Invite Merchant',
  },
  invite_received: {
    id: 'cargoenv.common.invite_received',
    description: '',
    defaultMessage: 'Invite received',
  },
  invite_sent: {
    id: 'cargoenv.common.invite_sent',
    description: '',
    defaultMessage: 'Invite sent',
  },
  invite_sent_message: {
    id: 'cargoenv.common.invite_sent_message',
    description: '',
    defaultMessage:
      'They will receive an e-mail with information about signing in to Teralo and connect to you. You can also copy the invite link below if you want to contact them elsewhere.',
  },
  invite_was_sent: {
    id: 'cargoenv.common.invite_was_sent',
    description: '',
    defaultMessage: 'Your invite was sent to',
  },
  invites_received: {
    id: 'cargoenv.common.invites_received',
    description: '',
    defaultMessage: 'Invites received',
  },
  invites_sent: {
    id: 'cargoenv.common.invites_sent',
    description: '',
    defaultMessage: 'Invites sent',
  },
  item_brand: {
    id: 'cargoenv.common.item_brand',
    description: '',
    defaultMessage: 'Brand',
  },
  items_transported: {
    id: 'cargoenv.common.items_transported',
    description: '',
    defaultMessage: 'Items transported',
  },
  kg: {
    id: 'cargoenv.common.kg',
    description: '',
    defaultMessage: 'kg',
  },
  kilometers_driven: {
    id: 'cargoenv.common.kilometers_driven',
    description: '',
    defaultMessage: 'Kilometres driven',
  },
  km: {
    id: 'cargoenv.common.km',
    description: '',
    defaultMessage: 'km',
  },
  last_name: {
    id: 'cargoenv.common.last_name',
    description: '',
    defaultMessage: 'Last name',
  },
  link_copied: {
    id: 'cargoenv.common.link_copied',
    description: '',
    defaultMessage: 'Link copied!',
  },
  liter: {
    id: 'cargoenv.common.liter',
    description: '',
    defaultMessage: 'Liter',
  },
  loading: {
    id: 'cargoenv.common.loading',
    description: '',
    defaultMessage: 'Loading...',
  },
  loading_reports: {
    id: 'cargoenv.common.loading_reports',
    description: '',
    defaultMessage: 'Loading reports. This may take a moment...',
  },
  loading_route: {
    id: 'cargoenv.common.loading_route',
    description: '',
    defaultMessage: 'Loading route...',
  },
  loading_routes: {
    id: 'cargoenv.common.loading_routes',
    description: '',
    defaultMessage: 'Loading routes...',
  },
  main_menu: {
    id: 'cargoenv.common.main_menu',
    description: '',
    defaultMessage: 'Main Menu',
  },
  merchant: {
    id: 'cargoenv.common.merchant',
    description: '',
    defaultMessage: 'Merchant',
  },
  merchants: {
    id: 'cargoenv.common.merchants',
    description: '',
    defaultMessage: 'Merchants',
  },
  message: {
    id: 'cargoenv.common.message',
    description: '',
    defaultMessage: 'Message',
  },
  message_placeholder: {
    id: 'cargoenv.common.message_placeholder',
    description: '',
    defaultMessage: 'Hi, we would like you to please sign up for Teralo...',
  },
  multiple_dates: {
    id: 'cargoenv.common.multiple_dates',
    description: '',
    defaultMessage: 'Multiple dates',
  },
  multiple_routes: {
    id: 'cargoenv.common.multiple_routes',
    description: '',
    defaultMessage: 'Multiple routes',
  },
  my_account: {
    id: 'cargoenv.common.my_account',
    description: '',
    defaultMessage: 'My account',
  },
  name: {
    id: 'cargoenv.common.name',
    description: '',
    defaultMessage: 'Name',
  },
  next: {
    id: 'cargoenv.common.next',
    description: '',
    defaultMessage: 'Next',
  },
  no_carriers_found: {
    id: 'cargoenv.common.no_carriers_found',
    description: '',
    defaultMessage: 'No carriers found',
  },
  no_connected_carriers: {
    id: 'cargoenv.common.no_connected_carriers',
    description: '',
    defaultMessage: 'No connected carriers',
  },
  no_connected_merchants: {
    id: 'cargoenv.common.no_connected_merchants',
    description: '',
    defaultMessage: 'No connected merchants',
  },
  no_data: {
    id: 'cargoenv.common.no_data',
    description: '',
    defaultMessage: 'No data',
  },
  no_data_to_display_for_the_selected_period: {
    id: 'cargoenv.common.no_data_to_display_for_the_selected_period',
    description: '',
    defaultMessage: 'No data to display for the selected period.',
  },
  no_dates_selected: {
    id: 'cargoenv.common.no_dates_selected',
    description: '',
    defaultMessage: 'No dates selected',
  },
  no_invites_received: {
    id: 'cargoenv.common.no_invites_received',
    description: '',
    defaultMessage: 'No invites received',
  },
  no_invites_received_message: {
    id: 'cargoenv.common.no_invites_received_message',
    description: '',
    defaultMessage: 'You have no new invites',
  },
  no_invites_sent: {
    id: 'cargoenv.common.no_invites_sent',
    description: '',
    defaultMessage: 'No invites sent',
  },
  no_transport_orders: {
    id: 'cargoenv.common.no_transport_orders',
    description: '',
    defaultMessage: 'No transport orders',
  },
  no_transport_orders_found: {
    id: 'cargoenv.common.no_transport_orders_found',
    description: '',
    defaultMessage: 'No transport orders found',
  },
  no_transports_found: {
    id: 'cargoenv.common.no_transports_found',
    description: '',
    defaultMessage: 'No transports found',
  },
  not_applicaple: {
    id: 'cargoenv.common.not_applicaple',
    description: '',
    defaultMessage: 'N/A',
  },
  not_set: {
    id: 'cargoenv.common.not_set',
    description: '',
    defaultMessage: 'Not set',
  },
  number: {
    id: 'cargoenv.common.number',
    description: '',
    defaultMessage: 'Number',
  },
  ok: {
    id: 'cargoenv.common.ok',
    description: '',
    defaultMessage: 'Ok',
  },
  online: {
    id: 'cargoenv.common.online',
    description: '',
    defaultMessage: 'Online',
  },
  only_after_that: {
    id: 'cargoenv.common.only_after_that',
    description: '',
    defaultMessage: 'Only after that you can start using the Teralo services.',
  },
  organisation: {
    id: 'cargoenv.common.organisation',
    description: '',
    defaultMessage: 'Organisation',
  },
  organisation_type: {
    id: 'cargoenv.common.organisation_type',
    description: '',
    defaultMessage: 'Organisation type',
  },
  page_not_found: {
    id: 'cargoenv.common.page_not_found',
    description: '',
    defaultMessage: 'Page not found',
  },
  page_not_found_description: {
    id: 'cargoenv.common.page_not_found_description',
    description: '',
    defaultMessage: 'The page you were looking for appears to have been moved or deleted, or does no longer exist.',
  },
  page_not_found_instruction: {
    id: 'cargoenv.common.page_not_found_instruction',
    description: '',
    defaultMessage: 'Use the navigation above or the link below to start over.',
  },
  password: {
    id: 'cargoenv.common.password',
    description: '',
    defaultMessage: 'Password',
  },
  pending_approval: {
    id: 'cargoenv.common.pending_approval',
    description: '',
    defaultMessage: 'Pending approval',
  },
  pending_invitations: {
    id: 'cargoenv.common.pending_invitations',
    description: '',
    defaultMessage: 'Pending invitations',
  },
  percent_of_order: {
    id: 'cargoenv.common.percent_of_order',
    description: '',
    defaultMessage: '% of order',
  },
  phone_number: {
    id: 'cargoenv.common.phone_number',
    description: '',
    defaultMessage: 'Phone number',
  },
  placeholder: {
    id: 'cargoenv.common.placeholder',
    description: '',
    defaultMessage: 'placeholder',
  },
  please_accept_the_invitation: {
    id: 'cargoenv.common.please_accept_the_invitation',
    description: '',
    defaultMessage: 'Please accept the invitation by following the link below:',
  },
  please_dont_hesitate: {
    id: 'cargoenv.common.please_dont_hesitate',
    description: '',
    defaultMessage: "Please, don't hesitate to reach out if any questions arise.",
  },
  please_note: {
    id: 'cargoenv.common.please_note',
    description: '',
    defaultMessage: 'Please note',
  },
  post_address: {
    id: 'cargoenv.common.post_address',
    description: '',
    defaultMessage: 'Post address',
  },
  product_no: {
    id: 'cargoenv.common.product_no',
    description: '',
    defaultMessage: 'Product no.',
  },
  product_number: {
    id: 'cargoenv.common.product_number',
    description: '',
    defaultMessage: 'Product number',
  },
  product_type: {
    id: 'cargoenv.common.product_type',
    description: '',
    defaultMessage: 'Product type',
  },
  registration_number: {
    id: 'cargoenv.common.registration_number',
    description: '',
    defaultMessage: 'Registration number',
  },
  remove: {
    id: 'cargoenv.common.remove',
    description: '',
    defaultMessage: 'Remove',
  },
  remove_connection: {
    id: 'cargoenv.common.remove_connection',
    description: '',
    defaultMessage: 'Remove connection',
  },
  remove_connection_title: {
    id: 'cargoenv.common.remove_connection_title',
    description: '',
    defaultMessage: 'Do you really want to remove the connection to {name}?',
  },
  remove_invite: {
    id: 'cargoenv.common.remove_invite',
    description: '',
    defaultMessage: 'Remove invite',
  },
  remove_selected: {
    id: 'cargoenv.common.remove_selected',
    description: '',
    defaultMessage: 'Remove selected',
  },
  repeat_password: {
    id: 'cargoenv.common.repeat_password',
    description: '',
    defaultMessage: 'Repeat password',
  },
  report: {
    id: 'cargoenv.common.report',
    description: '',
    defaultMessage: 'Report',
  },
  reports: {
    id: 'cargoenv.common.reports',
    description: '',
    defaultMessage: 'Reports',
  },
  required_field: {
    id: 'cargoenv.common.required_field',
    description: '',
    defaultMessage: 'This field is required',
  },
  retail: {
    id: 'cargoenv.common.retail',
    description: '',
    defaultMessage: 'Retail',
  },
  route: {
    id: 'cargoenv.common.route',
    description: '',
    defaultMessage: 'Route',
  },
  routes: {
    id: 'cargoenv.common.routes',
    description: '',
    defaultMessage: 'Routes',
  },
  save: {
    id: 'cargoenv.common.save',
    description: '',
    defaultMessage: 'Save',
  },
  save_and_close: {
    id: 'cargoenv.common.save_and_close',
    description: '',
    defaultMessage: 'Save & close',
  },
  save_changes: {
    id: 'cargoenv.common.save_changes',
    description: '',
    defaultMessage: 'Save changes?',
  },
  save_updates: {
    id: 'cargoenv.common.save_updates',
    description: '',
    defaultMessage: 'Save updates',
  },
  saved: {
    id: 'cargoenv.common.saved',
    description: '',
    defaultMessage: 'Saved',
  },
  saving: {
    id: 'cargoenv.common.saving',
    description: '',
    defaultMessage: 'Saving...',
  },
  score: {
    id: 'cargoenv.common.score',
    description: '',
    defaultMessage: 'Score',
  },
  search: {
    id: 'cargoenv.common.search',
    description: '',
    defaultMessage: 'Search',
  },
  select_a_value: {
    id: 'cargoenv.common.select_value',
    description: '',
    defaultMessage: 'Select a value',
  },
  selected_file: {
    id: 'cargoenv.common.selected_file',
    description: '',
    defaultMessage: 'Selected file',
  },
  send_invitation: {
    id: 'cargoenv.common.send_invitation',
    description: '',
    defaultMessage: 'Send invitation',
  },
  send_invite_again: {
    id: 'cargoenv.common.send_invite_again',
    description: '',
    defaultMessage: 'Send invite again',
  },
  sent_from: {
    id: 'cargoenv.common.sent_from',
    description: '',
    defaultMessage: 'Sent from {name} {date}',
  },
  show: {
    id: 'cargoenv.common.show',
    description: '',
    defaultMessage: 'Show',
  },
  show_all: {
    id: 'cargoenv.common.show_all',
    description: '',
    defaultMessage: 'Show all ({count})',
  },
  show_less: {
    id: 'cargoenv.common.show_less',
    description: '',
    defaultMessage: 'Show less',
  },
  showing: {
    id: 'cargoenv.common.showing',
    description: '',
    defaultMessage: 'Showing:',
  },
  sign_up_for_teralo: {
    id: 'cargoenv.common.sign_up_for_teralo',
    description: '',
    defaultMessage: 'Sign up for Teralo',
  },
  signUp: {
    id: 'cargoenv.common.signup',
    description: '',
    defaultMessage: 'Sign Up',
  },
  start: {
    id: 'cargoenv.common.start',
    description: '',
    defaultMessage: 'Start',
  },
  start_page: {
    id: 'cargoenv.common.start_page',
    description: '',
    defaultMessage: 'Start page',
  },
  sub_carrier: {
    id: 'cargoenv.common.sub_carrier',
    description: '',
    defaultMessage: 'Sub-carrier',
  },
  support: {
    id: 'cargoenv.common.support',
    description: '',
    defaultMessage: 'Support',
  },
  sustainability_score: {
    id: 'cargoenv.common.sustainability_score',
    description: '',
    defaultMessage: 'Sustainability score',
  },
  telephone_number: {
    id: 'cargoenv.common.telephone_number',
    description: '',
    defaultMessage: 'Telephone number',
  },
  teralo_empowers_you: {
    id: 'cargoenv.common.teralo_empowers_you',
    description: '',
    defaultMessage:
      'Teralo empowers you, your company and business partners to measure actual CO₂e footprints down to product item levels during transportation.',
  },
  teralo_wishes_you: {
    id: 'cargoenv.common.teralo_wishes_you',
    description: '',
    defaultMessage: 'Teralo wishes you a great day.',
  },
  terms_and_conditions: {
    id: 'cargoenv.common.terms_of_use_checkbox_label',
    description: '',
    defaultMessage: 'I accept the Teralo Terms of Use and ensure that the above information is correct.',
  },
  to: {
    id: 'cargoenv.common.to',
    description: '',
    defaultMessage: 'To',
  },
  total: {
    id: 'cargoenv.common.total',
    description: '',
    defaultMessage: 'Total',
  },
  total_co2e_emissions: {
    id: 'cargoenv.common.total_co2e_emissions',
    description: '',
    defaultMessage: 'Total CO₂e emissions',
  },
  total_kilometers_driven: {
    id: 'cargoenv.common.total_kilometers_driven',
    description: '',
    defaultMessage: 'Total kilometres driven',
  },
  total_transport: {
    id: 'cargoenv.common.total_transport',
    description: '',
    defaultMessage: 'Total transport',
  },
  transparency: {
    id: 'cargoenv.common.transparency',
    description: '',
    defaultMessage: 'Transparency',
  },
  transport: {
    id: 'cargoenv.common.transport',
    description: '',
    defaultMessage: 'Transport',
  },
  transport_buyer: {
    id: 'cargoenv.common.transport_buyer',
    description: '',
    defaultMessage: 'Transport buyer',
  },
  transport_details_title: {
    id: 'cargoenv.common.transport_details_title',
    description: '',
    defaultMessage: 'Transport Details',
  },
  transport_mode: {
    id: 'cargoenv.common.transport_mode',
    description: '',
    defaultMessage: 'Transport mode',
  },
  transport_order_details_title: {
    id: 'cargoenv.common.transport_order_details_title',
    description: '',
    defaultMessage: 'Transport Order Details',
  },
  transport_order_no: {
    id: 'cargoenv.common.transport_order_no',
    description: '',
    defaultMessage: 'Transport order no.',
  },
  transport_order_number_title: {
    id: 'cargoenv.common.transport_order_number_title',
    description: '',
    defaultMessage: 'Transport Order Number',
  },
  transport_order_title: {
    id: 'cargoenv.common.transport_order_title',
    description: '',
    defaultMessage: 'Transport Order',
  },
  transport_order_weight: {
    id: 'cargoenv.common.transport_order_weight',
    description: '',
    defaultMessage: 'Transport order weight',
  },
  transport_orders: {
    id: 'cargoenv.common.transport_orders',
    description: '',
    defaultMessage: 'Transport orders',
  },
  transport_order_count: {
    id: 'cargoenv.common.transport_orders_count',
    description: '',
    defaultMessage: '{count, plural, one {# Transport order} other {# Transport orders}}',
  },
  transport_orders_report: {
    id: 'cargoenv.common.transport_orders_report',
    description: '',
    defaultMessage: 'Transport Orders report',
  },
  transport_phases: {
    id: 'cargoenv.common.transport_phases',
    description: '',
    defaultMessage: 'Transport phases',
  },
  transport_phases_title: {
    id: 'cargoenv.common.transport_phases_title',
    description: '',
    defaultMessage: 'Transport Phases',
  },
  transport_report: {
    id: 'cargoenv.common.transport_report',
    description: '',
    defaultMessage: 'Transport report',
  },
  transport_summary: {
    id: 'cargoenv.common.transport_summary',
    description: '',
    defaultMessage: 'Transport Summary',
  },
  transports: {
    id: 'cargoenv.common.transports',
    description: '',
    defaultMessage: 'Transports',
  },
  transports_count: {
    id: 'cargoenv.common.transports_count',
    description: '',
    defaultMessage: '{count, plural, one {# transport} other {# transports}}',
  },
  true_transparency: {
    id: 'cargoenv.common.true_transparency',
    description: '',
    defaultMessage: 'True transparency',
  },
  unknown: {
    id: 'cargoenv.common.unknown',
    description: '',
    defaultMessage: 'Unknown',
  },
  unknown_error: {
    id: 'cargoenv.common.unknown_error',
    description: '',
    defaultMessage: 'Unknown error',
  },
  upload_csv: {
    id: 'cargoenv.common.upload_csv',
    description: '',
    defaultMessage: 'Upload csv',
  },
  upload_file: {
    id: 'cargoenv.common.upload_file',
    description: '',
    defaultMessage: 'Upload file',
  },
  username: {
    id: 'cargoenv.common.username',
    description: '',
    defaultMessage: 'Username',
  },
  users: {
    id: 'cargoenv.common.users',
    description: '',
    defaultMessage: 'Users',
  },
  vat_number: {
    id: 'cargoenv.common.vat_number',
    description: '',
    defaultMessage: 'VAT number',
  },
  vehicle: {
    id: 'cargoenv.common.vehicle',
    description: '',
    defaultMessage: 'Vehicle',
  },
  vehicle_name: {
    id: 'cargoenv.common.vehicle_name',
    description: '',
    defaultMessage: 'Vehicle name',
  },
  vehicles: {
    id: 'cargoenv.common.vehicles',
    description: '',
    defaultMessage: 'Vehicles',
  },
  articulated_hauler_label: {
    id: 'cargoenv.common.vehicleTypes.articulated_hauler_label',
    description: '',
    defaultMessage: 'Articulated hauler',
  },
  distribution_truck_label: {
    id: 'cargoenv.common.vehicleTypes.distribution_truck_label',
    description: '',
    defaultMessage: 'Distribution truck',
  },
  train: {
    id: 'cargoenv.common.vehicleTypes.train_label',
    description: '',
    defaultMessage: 'Train',
  },
  van: {
    id: 'cargoenv.common.vehicleTypes.van_label',
    description: '',
    defaultMessage: 'Van',
  },
  view_sent_invites: {
    id: 'cargoenv.common.view_sent_invites',
    description: '',
    defaultMessage: 'View sent invitations',
  },
  we_have_now_created: {
    id: 'cargoenv.common.we_have_now_created',
    description: '',
    defaultMessage: 'We have now created your organization {orgName} in Teralo',
  },
  weight_per_item: {
    id: 'cargoenv.common.weight_per_item',
    description: '',
    defaultMessage: 'Weight per item',
  },
  weight_total: {
    id: 'cargoenv.common.weight_total',
    description: '',
    defaultMessage: 'Weight total',
  },
  your_email: {
    id: 'cargoenv.common.your_email',
    description: '',
    defaultMessage: 'Your E-mail',
  },
  confirm_delete_user: {
    id: 'cargoenv.confirm.delete.user',
    description: '',
    defaultMessage: 'You are about to delete user',
  },
  connectedServices: {
    id: 'cargoenv.connected_services.title',
    description: '',
    defaultMessage: 'Connected Services',
  },
  carrier_vehicle_name: {
    id: 'cargoenv.customReports.carrier_vehicle_name',
    description: '',
    defaultMessage: 'Carrier/Vehicle name',
  },
  transport_reports: {
    id: 'cargoenv.customReportsFollowUp.transport_reports',
    description: '',
    defaultMessage: 'Transport reports',
  },
  add_vehicles: {
    id: 'cargoenv.dashboard.add_vehicles',
    description: '',
    defaultMessage: 'Add vehicles',
  },
  all_brands: {
    id: 'cargoenv.dashboard.all_brands',
    description: '',
    defaultMessage: 'All brands',
  },
  all_carriers: {
    id: 'cargoenv.dashboard.all_carriers',
    description: '',
    defaultMessage: 'All carriers',
  },
  all_customers: {
    id: 'cargoenv.dashboard.all_customers',
    description: '',
    defaultMessage: 'All Customers',
  },
  b2b_integrations: {
    id: 'cargoenv.dashboard.b2b_integrations',
    description: '',
    defaultMessage: 'B2B integrations',
  },
  brands: {
    id: 'cargoenv.dashboard.brands',
    description: '',
    defaultMessage: 'Brands',
  },
  current_emissions: {
    id: 'cargoenv.dashboard.current_emissions',
    description: '',
    defaultMessage: 'Current emissions',
  },
  custom_time_period: {
    id: 'cargoenv.dashboard.custom_time_period',
    description: '',
    defaultMessage: 'Custom time period ({startDate} - {endDate})',
  },
  distance_and_energy_mix: {
    id: 'cargoenv.dashboard.distance_and_energy_mix',
    description: '',
    defaultMessage: 'Distance and energy mix',
  },
  edit_permissions: {
    id: 'cargoenv.dashboard.edit_permissions',
    description: '',
    defaultMessage: 'Edit permissions',
  },
  emission: {
    id: 'cargoenv.dashboard.emission',
    description: '',
    defaultMessage: 'Emission',
  },
  emission_target: {
    id: 'cargoenv.dashboard.emission_target',
    description: '',
    defaultMessage: 'Emission target',
  },
  error_loading_data: {
    id: 'cargoenv.dashboard.error_loading_data',
    description: '',
    defaultMessage: 'Error loading data',
  },
  export_report: {
    id: 'cargoenv.dashboard.export_report',
    description: '',
    defaultMessage: 'Export report',
  },
  filter: {
    id: 'cargoenv.dashboard.filter',
    description: '',
    defaultMessage: 'Filter',
  },
  go_ahead_and_start: {
    id: 'cargoenv.dashboard.go_ahead_and_start',
    description: '',
    defaultMessage: 'Go ahead and start adding and connecting your vehicles to be able to collect your transport data.',
  },
  go_ahead_and_start_reports: {
    id: 'cargoenv.dashboard.go_ahead_and_start_reports',
    description: '',
    defaultMessage: 'Go ahead and start looking at reports based on your transport orders.',
  },
  goods_transported: {
    id: 'cargoenv.dashboard.goods_transported',
    description: '',
    defaultMessage: 'Goods transported with Connected Vehicles',
  },
  goods_volume_transported: {
    id: 'cargoenv.dashboard.goods_volume_transported',
    description: '',
    defaultMessage: 'Goods volume transported',
  },
  handle_your_b2b_integrations_and: {
    id: 'cargoenv.dashboard.handle_your_b2b_integrations_and',
    description: '',
    defaultMessage: 'Handle your B2B integrations and API names for your assigned carriers.',
  },
  intensity: {
    id: 'cargoenv.dashboard.intensity',
    description: '',
    defaultMessage: 'Intensity',
  },
  last_30_days_period: {
    id: 'cargoenv.dashboard.last_30_days_period',
    description: '',
    defaultMessage: 'Viewing last 30 days ({startDate} - {endDate})',
  },
  last_mile: {
    id: 'cargoenv.dashboard.last_mile',
    description: '',
    defaultMessage: 'Last mile',
  },
  last_month_period: {
    id: 'cargoenv.dashboard.last_month_period',
    description: '',
    defaultMessage: 'Viewing last month ({startDate} - {endDate})',
  },
  let_your_merchants_know: {
    id: 'cargoenv.dashboard.let_your_merchants_know',
    description: '',
    defaultMessage: 'Let your merchants know that you are using Teralo and invite them to connect.',
  },
  line_haul: {
    id: 'cargoenv.dashboard.line_haul',
    description: '',
    defaultMessage: 'Line haul',
  },
  noDataPreviousPeriod: {
    id: 'cargoenv.dashboard.no_data_previous_period',
    description: '',
    defaultMessage: 'no previous data to compare to',
  },
  package: {
    id: 'cargoenv.dashboard.package',
    description: '',
    defaultMessage: 'Package',
  },
  period_custom: {
    id: 'cargoenv.dashboard.period_custom',
    description: '',
    defaultMessage: 'Set time period',
  },
  period_last_30_days: {
    id: 'cargoenv.dashboard.period_last_30_days',
    description: '',
    defaultMessage: 'Last 30 days',
  },
  previous_month: {
    id: 'cargoenv.dashboard.previous_month',
    description: '',
    defaultMessage: 'Previous Month',
  },
  sustainability_scores: {
    id: 'cargoenv.dashboard.sustainability_scores',
    description: '',
    defaultMessage: 'Sustainability scores',
  },
  targets: {
    id: 'cargoenv.dashboard.targets',
    description: '',
    defaultMessage: 'Targets (Ton)',
  },
  dashboard: {
    id: 'cargoenv.dashboard.title',
    description: '',
    defaultMessage: 'Dashboard',
  },
  to_start_with: {
    id: 'cargoenv.dashboard.to_start_with',
    description: '',
    defaultMessage:
      'To start with you need to edit your permissions. Click on the button below to give yourself the right roles.',
  },
  toast_error_fetching_vehicles: {
    id: 'cargoenv.dashboard.toast_error_fetching_vehicles',
    description: '',
    defaultMessage: 'Error fetching vehicles',
  },
  tonKm: {
    id: 'cargoenv.dashboard.tonKm',
    description: '',
    defaultMessage: 'tonKm',
  },
  transport_service_customers: {
    id: 'cargoenv.dashboard.transport_service_customers',
    description: '',
    defaultMessage: 'Transport service customers',
  },
  unit: {
    id: 'cargoenv.dashboard.unit',
    description: '',
    defaultMessage: 'Unit',
  },
  view_and_edit_sustainability_score: {
    id: 'cargoenv.dashboard.view_and_edit_sustainability_score',
    description: '',
    defaultMessage: 'View and edit your sustainability scores that will be shown in your reports.',
  },
  view_carriers: {
    id: 'cargoenv.dashboard.view_carriers',
    description: '',
    defaultMessage: 'View carriers',
  },
  view_if_you_are_using_carriers: {
    id: 'cargoenv.dashboard.view_if_you_are_using_carriers',
    description: '',
    defaultMessage:
      'If you are using carriers for your transports you can see them by clicking the button or Carriers in the menu to the left.',
  },
  view_if_you_are_using_merchants: {
    id: 'cargoenv.dashboard.view_if_you_are_using_merchants',
    description: '',
    defaultMessage: 'You can see your connected merchants by clicking the button or Merchants in the menu to the left.',
  },
  view_merchant: {
    id: 'cargoenv.dashboard.view_merchant',
    description: '',
    defaultMessage: 'View merchants',
  },
  view_reports: {
    id: 'cargoenv.dashboard.view_reports',
    description: '',
    defaultMessage: 'View reports',
  },
  view_routes: {
    id: 'cargoenv.dashboard.view_routes',
    description: '',
    defaultMessage: 'View routes',
  },
  view_transport: {
    id: 'cargoenv.dashboard.view_transport',
    description: '',
    defaultMessage: 'View transports',
  },
  view_transport_order: {
    id: 'cargoenv.dashboard.view_transport_order',
    description: '',
    defaultMessage: 'View transport orders',
  },
  view_vehicles: {
    id: 'cargoenv.dashboard.view_vehicles',
    description: '',
    defaultMessage: 'View vehicles',
  },
  vsPreviousPeriod: {
    id: 'cargoenv.dashboard.vs_previous_period',
    description: '',
    defaultMessage: 'vs. previous period',
  },
  welcome_to_teralo: {
    id: 'cargoenv.dashboard.welcome_to_teralo',
    description: '',
    defaultMessage: 'Welcome to Teralo',
  },
  what_do_you_want: {
    id: 'cargoenv.dashboard.what_do_you_want',
    description: '',
    defaultMessage: 'What do you want to do next?',
  },
  you_can_view_and_create_route: {
    id: 'cargoenv.dashboard.you_can_view_and_create_route',
    description: '',
    defaultMessage:
      'You can view and create new routes by clicking the button below or Routes in the menu to the left.',
  },
  you_can_view_and_create_transport: {
    id: 'cargoenv.dashboard.you_can_view_and_create_transport',
    description: '',
    defaultMessage:
      'You can view and create new transport by clicking the button below or Transports in the menu to the left.',
  },
  you_can_view_and_create_transport_order: {
    id: 'cargoenv.dashboard.you_can_view_and_create_transport_order',
    description: '',
    defaultMessage:
      'You can view and create new transport order by clicking the button below or Transport orders in the menu to the left.',
  },
  you_can_view_route: {
    id: 'cargoenv.dashboard.you_can_view_route',
    description: '',
    defaultMessage: 'You can view your routes by clicking the button below or Routes in the menu to the left.',
  },
  you_can_view_transport: {
    id: 'cargoenv.dashboard.you_can_view_transport',
    description: '',
    defaultMessage: 'You can view your transports by clicking the button below or Transports in the menu to the left.',
  },
  you_can_view_transport_orders: {
    id: 'cargoenv.dashboard.you_can_view_transport_orders',
    description: '',
    defaultMessage:
      'You can view your transport orders by clicking the button below or Transport orders in the menu to the left.',
  },
  you_can_view_vehicles: {
    id: 'cargoenv.dashboard.you_can_view_vehicles',
    description: '',
    defaultMessage: 'You can view your vehicles by clicking the button below or Vehicles in the menu to the left.',
  },
  your_reports: {
    id: 'cargoenv.dashboard.your_reports',
    description: '',
    defaultMessage: 'Your reports',
  },
  edit_user: {
    id: 'cargoenv.edit_user.title',
    description: '',
    defaultMessage: 'Edit user',
  },
  emissionTargets: {
    id: 'cargoenv.emission_targets.title',
    description: '',
    defaultMessage: 'Emission Targets',
  },
  got_emptyApiStoreResponse: {
    id: 'cargoenv.emptyApiStoreResponse',
    description: '',
    defaultMessage: 'Got empty API response when storing.',
  },
  brands_selected: {
    id: 'cargoenv.filters.brands_selected',
    description: '',
    defaultMessage: '{amount} brands selected',
  },
  customers_selected: {
    id: 'cargoenv.filters.customers_selected',
    description: '',
    defaultMessage: '{amount} customers selected',
  },
  error_fetching_customers: {
    id: 'cargoenv.filters.error_fetching_customers',
    description: '',
    defaultMessage: 'Error fetching customers',
  },
  filter_tool_tip_message: {
    id: 'cargoenv.filters.filter_tool_tip_message',
    description: '',
    defaultMessage:
      'The filter options only contain items that is related to emissions from transports that has already been carried out and reported in to Teralo.',
  },
  loading_brands: {
    id: 'cargoenv.filters.loading_brands',
    description: '',
    defaultMessage: 'Loading brands...',
  },
  loading_customers: {
    id: 'cargoenv.filters.loading_customers',
    description: '',
    defaultMessage: 'Loading customers...',
  },
  no_brands_found: {
    id: 'cargoenv.filters.no_brands_found',
    description: '',
    defaultMessage: 'No brands found',
  },
  no_customers_found: {
    id: 'cargoenv.filters.no_customers_found',
    description: '',
    defaultMessage: 'No customers found',
  },
  select_brands: {
    id: 'cargoenv.filters.select_brands',
    description: '',
    defaultMessage: 'Select {count, plural, one {brand} other {brands}}',
  },
  select_carriers: {
    id: 'cargoenv.filters.select_carriers',
    description: '',
    defaultMessage: 'Select {count, plural, one {carrier} other {carriers}}',
  },
  select_customers: {
    id: 'cargoenv.filters.select_customers',
    description: '',
    defaultMessage: 'Select {count, plural, one {customer} other {customers}}',
  },
  forgot_password: {
    id: 'cargoenv.forgot_password.title',
    description: '',
    defaultMessage: 'Forgot password',
  },
  ignition_gas: {
    id: 'cargoenv.fuelType.ignition_gas',
    description: '',
    defaultMessage: 'Ignition/Gas',
  },
  renewable: {
    id: 'cargoenv.fuelType.renewable',
    description: '',
    defaultMessage: 'Renewable',
  },
  help_and_support: {
    id: 'cargoenv.helpAndSupport.help_and_support',
    description: '',
    defaultMessage: 'Help & Support',
  },
  phone: {
    id: 'cargoenv.helpAndSupport.phone',
    description: '',
    defaultMessage: 'Phone:',
  },
  support_info: {
    id: 'cargoenv.helpAndSupport.support_info',
    description: '',
    defaultMessage:
      'We are here to assist you with any questions or concerns you may have regarding Teralo. Our dedicated support team is available to provide you with timely and effective solutions. We value your feedback and continuously work towards improving our services based on your input. Let us know how we can assist you today!',
  },
  login_password_requirements_include_at_least: {
    id: 'cargoenv.login_password_requirements_include_at_least',
    description: '',
    defaultMessage: 'Include at least 1 character of the following types:',
  },
  login_password_requirements_min_max: {
    id: 'cargoenv.login_password_requirements_min_max',
    description: '',
    defaultMessage: 'Include at least 12 characters and no more than 99 characters',
  },
  login_password_requirements_must: {
    id: 'cargoenv.login_password_requirements_must',
    description: '',
    defaultMessage: 'Password must',
  },
  login_password_requirements_the_same: {
    id: 'cargoenv.login_password_requirements_the_same',
    description: '',
    defaultMessage: 'Be the same in both instances',
  },
  login_set_password_requirements_lowercase: {
    id: 'cargoenv.login_set_password_requirements_lowercase',
    description: '',
    defaultMessage: 'Lowercase Basic Latin letter',
  },
  login_set_password_requirements_special_character: {
    id: 'cargoenv.login_set_password_requirements_special_character',
    description: '',
    defaultMessage: 'Special character',
  },
  login_set_password_requirements_uppercase: {
    id: 'cargoenv.login_set_password_requirements_uppercase',
    description: '',
    defaultMessage: 'Uppercase Basic Latin letter',
  },
  code: {
    id: 'cargoenv.login.code',
    description: '',
    defaultMessage: 'Code',
  },
  login_email_label: {
    id: 'cargoenv.login.email',
    description: '',
    defaultMessage: 'Email',
  },
  email_placeholder: {
    id: 'cargoenv.login.email_placeholder',
    description: '',
    defaultMessage: 'Enter your email',
  },
  error_change_password: {
    id: 'cargoenv.login.error_change_password',
    description: '',
    defaultMessage: 'Could not change the password',
  },
  error_could_not_login: {
    id: 'cargoenv.login.error_could_not_login',
    description: '',
    defaultMessage: 'Could not log in',
  },
  error_getting_organizations_contact_support: {
    id: 'cargoenv.login.error_getting_organizations_contact_support',
    description: '',
    defaultMessage: 'Error getting organizations, please contact Teralo support.',
  },
  error_not_authorized: {
    id: 'cargoenv.login.error_not_authorized',
    description: '',
    defaultMessage: 'Incorrect username or password',
  },
  error_resource_not_found: {
    id: 'cargoenv.login.error_resource_not_found',
    description: '',
    defaultMessage: 'There was problem with the connection',
  },
  forgot_password_question: {
    id: 'cargoenv.login.forgot_password',
    description: '',
    defaultMessage: 'Forgot password?',
  },
  forgot_password_info: {
    id: 'cargoenv.login.forgot_password_info',
    description: '',
    defaultMessage:
      "Enter your email address below and we'll send you an email with instructions on how to reset your password.",
  },
  invite_received_description: {
    id: 'cargoenv.login.invite_received_description',
    description: '',
    defaultMessage:
      'You have been invited to connect to new organisation. Sign in or create a new account to setup the connection.',
  },
  login_intro_text: {
    id: 'cargoenv.login.login_intro_text',
    description: '',
    defaultMessage: 'Please start by signing in',
  },
  login_quote: {
    id: 'cargoenv.login.login_quote',
    description: '',
    defaultMessage:
      'We have three main pain points: poor data quality, the fact that we only get it every quarter & that it is bulk data with no granularity.',
  },
  login_testimonial: {
    id: 'cargoenv.login.login_testimonial',
    description: '',
    defaultMessage: 'Transport Buyer',
  },
  login_testimonial_signoff: {
    id: 'cargoenv.login.login_testimonial_signoff',
    description: '',
    defaultMessage: 'Swedish transport buyer',
  },
  login_title: {
    id: 'cargoenv.login.login_title',
    description: '',
    defaultMessage: 'Sign in to Teralo',
  },
  password_changed_successfully: {
    id: 'cargoenv.login.password_changed_successfully',
    description: '',
    defaultMessage: 'Password changed successfully',
  },
  password_error_message: {
    id: 'cargoenv.login.password_error_message',
    description: '',
    defaultMessage: 'Password does not meet the criteria',
  },
  reset_password: {
    id: 'cargoenv.login.reset_password',
    description: '',
    defaultMessage: 'Reset password',
  },
  reset_password_error: {
    id: 'cargoenv.login.reset_password_error',
    description: '',
    defaultMessage: 'Could not reset password for this account. Please check if the email is valid',
  },
  reset_password_success: {
    id: 'cargoenv.login.reset_password_success',
    description: '',
    defaultMessage: 'Reset password email was sent successfully',
  },
  sign_up_Label: {
    id: 'cargoenv.login.sign_up_Label',
    description: '',
    defaultMessage: "Don't have a Teralo account?",
  },
  sign_up_link_label: {
    id: 'cargoenv.login.sign_up_link_label',
    description: '',
    defaultMessage: 'Create a Teralo account',
  },
  temporary_password: {
    id: 'cargoenv.login.temporary_password',
    description: '',
    defaultMessage: 'Temporary password',
  },
  expires_at: {
    id: 'cargoenv.merchant_carrier.expires_at',
    description: '',
    defaultMessage: 'Expires at {date}',
  },
  merchant_dashboard_description: {
    id: 'cargoenv.merchant_dashboard_description',
    description: '',
    defaultMessage:
      'View and export information about CO2 Emissions, Energy Mix, Transport phases and goods transported with connected vehicles',
  },
  merchant_dashboard_label: {
    id: 'cargoenv.merchant_dashboard_label',
    description: '',
    defaultMessage: 'Widget Dashboard',
  },
  merchant_relation_admin_description: {
    id: 'cargoenv.merchant_relation_admin_description',
    description: '',
    defaultMessage: 'Invite, accept incoming invites and handle relations with merchants',
  },
  merchant_relation_admin_label: {
    id: 'cargoenv.merchant_relation_admin_label',
    description: '',
    defaultMessage: 'Merchant Relation Administrator',
  },
  merchant_relation_viewer_description: {
    id: 'cargoenv.merchant_relation_viewer_description',
    description: '',
    defaultMessage: 'View relations with merchants',
  },
  merchant_relation_viewer_label: {
    id: 'cargoenv.merchant_relation_viewer_label',
    description: '',
    defaultMessage: 'Merchant Relation Viewer',
  },
  merchant_email: {
    id: 'cargoenv.merchant.email',
    description: '',
    defaultMessage: "Merchant's email",
  },
  invite_new_merchant: {
    id: 'cargoenv.merchant.invite_new_merchant',
    description: '',
    defaultMessage: 'Invite new merchant',
  },
  invite_new_merchant_text: {
    id: 'cargoenv.merchant.invite_new_merchant_text',
    description: '',
    defaultMessage:
      'Invite your merchant and let them know of Teralo. Once the merchant is signed up they can start managing transport data',
  },
  merchant_name: {
    id: 'cargoenv.merchant.name',
    description: '',
    defaultMessage: 'Merchant name',
  },
  merchant_phoneNumber: {
    id: 'cargoenv.merchant.phoneNumber',
    description: '',
    defaultMessage: "Merchant's phone number",
  },
  send_merchant_invitation_text: {
    id: 'cargoenv.merchant.send_invitation_text',
    description: '',
    defaultMessage:
      'Provide information about the merchant you want to invite to Teralo. We will then send an e-mail to them regarding sign up.',
  },
  connected_merchants: {
    id: 'cargoenv.merchants.connected_merchants',
    description: '',
    defaultMessage: 'Connected merchants',
  },
  error_delete_invite_merchant: {
    id: 'cargoenv.merchants.error_delete_invite',
    description: '',
    defaultMessage: 'Failed to delete merchant invitation(s)',
  },
  error_delete_merchant: {
    id: 'cargoenv.merchants.error_delete_merchant',
    description: '',
    defaultMessage: 'Failed to delete merchant connection',
  },
  error_fetch_merchant: {
    id: 'cargoenv.merchants.error_fetch_merchant',
    description: '',
    defaultMessage: 'Could not fetch merchant data',
  },
  merchants_name: {
    id: 'cargoenv.merchants.name',
    description: '',
    defaultMessage: "Merchant's name",
  },
  no_invites_sent_message_merchant: {
    id: 'cargoenv.merchants.no_invites_sent_message',
    description: '',
    defaultMessage: 'There are no invites sent. You can invite a merchant by clicking on the button below',
  },
  no_merchants_message: {
    id: 'cargoenv.merchants.no_merchants_message',
    description: '',
    defaultMessage:
      'You have no connected merchants yet. Once you are connected to at least one merchant they will be visible here.',
  },
  remove_connection_dialog_merchant: {
    id: 'cargoenv.merchants.remove_connection_dialog',
    description: '',
    defaultMessage:
      'You are about to remove the connection to the merchant {merchantName}. You will no longer receive the transport data from your merchant. The previous transport data will not be removed, but you will not be able to filter on that specific merchant. You need to make a new connection with the merchant if you want to receive new transport data.',
  },
  remove_invite_title_merchant: {
    id: 'cargoenv.merchants.remove_invite_title',
    description: '',
    defaultMessage: 'Do you really want to remove the pending invite to this merchant?',
  },
  order_report_admin_description: {
    id: 'cargoenv.order_report_admin_description',
    description: '',
    defaultMessage: 'View, filter and sort transport order reports',
  },
  order_report_admin_label: {
    id: 'cargoenv.order_report_admin_label',
    description: '',
    defaultMessage: 'Order Report Administrator',
  },
  org_admin_description: {
    id: 'cargoenv.org_admin_description',
    description: '',
    defaultMessage: 'Handle user roles and organizational invites',
  },
  org_admin_label: {
    id: 'cargoenv.org_admin_label',
    description: '',
    defaultMessage: 'Organization Administrator',
  },
  assigned_carriers_selected: {
    id: 'cargoenv.reports.assigned_carriers_selected',
    description: '',
    defaultMessage: '{count, plural, one {# carrier} other {# carriers} } selected',
  },
  cargo_percentage: {
    id: 'cargoenv.reports.cargo_percentage',
    description: '',
    defaultMessage: '% of cargo',
  },
  carriers_selected: {
    id: 'cargoenv.reports.carriers_selected',
    description: '',
    defaultMessage: '{amount} carriers selected',
  },
  error_fetching_carriers: {
    id: 'cargoenv.reports.error_fetching_carriers',
    description: '',
    defaultMessage: 'Failed to fetch carriers',
  },
  error_fetching_products: {
    id: 'cargoenv.reports.error_fetching_products',
    description: '',
    defaultMessage: 'Failed to fetch products',
  },
  error_fetching_report: {
    id: 'cargoenv.reports.error_fetching_report',
    description: '',
    defaultMessage: 'Error while fetching the report.',
  },
  error_fetching_route_destinations: {
    id: 'cargoenv.reports.error_fetching_route_destinations',
    description: '',
    defaultMessage: 'Failed to fetch route destinations',
  },
  error_fetching_route_starts: {
    id: 'cargoenv.reports.error_fetching_route_starts',
    description: '',
    defaultMessage: 'Failed to fetch route starts',
  },
  error_fetching_transport_buyers: {
    id: 'cargoenv.reports.error_fetching_transport_buyers',
    description: '',
    defaultMessage: 'Failed to fetch transport buyers',
  },
  error_fetching_transport_orders: {
    id: 'cargoenv.reports.error_fetching_transport_orders',
    description: '',
    defaultMessage: 'Failed to fetch transport orders...',
  },
  export_tooltip: {
    id: 'cargoenv.reports.export_tooltip',
    description: '',
    defaultMessage:
      'To export all order reports you first need to scroll to the end of the list or press on "Load all" button in the bottom right corner of the table.',
  },
  fetching_carriers: {
    id: 'cargoenv.reports.fetching_carriers',
    description: '',
    defaultMessage: 'Fetching carriers...',
  },
  fetching_products: {
    id: 'cargoenv.reports.fetching_products',
    description: '',
    defaultMessage: 'Fetching products...',
  },
  fetching_report: {
    id: 'cargoenv.reports.fetching_report',
    description: '',
    defaultMessage: 'Fetching report...',
  },
  fetching_route_destinations: {
    id: 'cargoenv.reports.fetching_route_destinations',
    description: '',
    defaultMessage: 'Fetching route destinations...',
  },
  fetching_route_starts: {
    id: 'cargoenv.reports.fetching_route_starts',
    description: '',
    defaultMessage: 'Fetching route starts...',
  },
  fetching_routes: {
    id: 'cargoenv.reports.fetching_routes',
    description: '',
    defaultMessage: 'Fetching routes...',
  },
  fetching_transport_buyers: {
    id: 'cargoenv.reports.fetching_transport_buyers',
    description: '',
    defaultMessage: 'Fetching transport buyers...',
  },
  fetching_transport_orders: {
    id: 'cargoenv.reports.fetching_transport_orders',
    description: '',
    defaultMessage: 'Fetching transport orders...',
  },
  fetching_transports: {
    id: 'cargoenv.reports.fetching_transports',
    description: '',
    defaultMessage: 'Fetching transports...',
  },
  fetching_vehicles: {
    id: 'cargoenv.reports.fetching_vehicles',
    description: '',
    defaultMessage: 'Fetching vehicles...',
  },
  from_time: {
    id: 'cargoenv.reports.from_time',
    description: '',
    defaultMessage: 'From time',
  },
  fuel_used_litres: {
    id: 'cargoenv.reports.fuel_used_litres',
    description: '',
    defaultMessage: 'Fuel used (litres)',
  },
  load_all: {
    id: 'cargoenv.reports.load_all',
    description: '',
    defaultMessage: 'Load all',
  },
  no_data_to_display: {
    id: 'cargoenv.reports.no_data_to_display',
    description: '',
    defaultMessage: 'No data to display. Check if user has the correct permissions',
  },
  no_products: {
    id: 'cargoenv.reports.no_products',
    description: '',
    defaultMessage: 'No products found',
  },
  no_route_destinations: {
    id: 'cargoenv.reports.no_route_destinations',
    description: '',
    defaultMessage: 'No route destinations found',
  },
  no_route_starts: {
    id: 'cargoenv.reports.no_route_starts',
    description: '',
    defaultMessage: 'No route starts found',
  },
  no_routes_found: {
    id: 'cargoenv.reports.no_routes',
    description: '',
    defaultMessage: 'No routes found',
  },
  no_transport_buyers: {
    id: 'cargoenv.reports.no_transport_buyers',
    description: '',
    defaultMessage: 'No transport buyers found',
  },
  no_vehicles_found: {
    id: 'cargoenv.reports.no_vehicles_found',
    description: '',
    defaultMessage: 'No vehicles found',
  },
  nox: {
    id: 'cargoenv.reports.nox',
    description: '',
    defaultMessage: 'NOX (kg)',
  },
  planned_dates: {
    id: 'cargoenv.reports.planned_dates',
    description: '',
    defaultMessage: 'Planned dates',
  },
  pm: {
    id: 'cargoenv.reports.pm',
    description: '',
    defaultMessage: 'PM',
  },
  print_info: {
    id: 'cargoenv.reports.print_info',
    description: '',
    defaultMessage:
      'To fit all columns when exporting to print, you can change the column widths by hovering over a column and drag the black line that appears to the right.',
  },
  print_tooltip: {
    id: 'cargoenv.reports.print_tooltip',
    description: '',
    defaultMessage: 'Please note: generating a print preview may take longer if the table contains many rows.',
  },
  product: {
    id: 'cargoenv.reports.product',
    description: '',
    defaultMessage: 'Product(s)',
  },
  products_selected: {
    id: 'cargoenv.reports.products_selected',
    description: '',
    defaultMessage: '{amount} products selected',
  },
  route_destinations_selected: {
    id: 'cargoenv.reports.route_destinations_selected',
    description: '',
    defaultMessage: '{amount} route destinations selected',
  },
  route_placeholder: {
    id: 'cargoenv.reports.route_placeholder',
    description: '',
    defaultMessage: 'Select route(s)',
  },
  route_starts_selected: {
    id: 'cargoenv.reports.route_starts_selected',
    description: '',
    defaultMessage: '{amount} route starts selected',
  },
  route_destination: {
    id: 'cargoenv.reports.route.destination',
    description: '',
    defaultMessage: 'Route destination(s)',
  },
  route_start: {
    id: 'cargoenv.reports.route.start',
    description: '',
    defaultMessage: 'Route start(s)',
  },
  routes_selected: {
    id: 'cargoenv.reports.routes_selected',
    description: '',
    defaultMessage: '{amount} routes selected',
  },
  score_tooltip: {
    id: 'cargoenv.reports.score_tooltip',
    description: '',
    defaultMessage:
      'Average Score for performed transport legs and their full cargo loads, whereof goods in this transport order is part of',
  },
  select_vehicles: {
    id: 'cargoenv.reports.select_vehicles',
    description: '',
    defaultMessage: 'Select {count, plural, one {vehicle} other {vehicles}}',
  },
  so2: {
    id: 'cargoenv.reports.so2',
    description: '',
    defaultMessage: 'SO₂',
  },
  summary_transports_tooltip: {
    id: 'cargoenv.reports.summary_transports_tooltip',
    description: '',
    defaultMessage: 'Accumulated number of transport phases for all transport orders',
  },
  to_time: {
    id: 'cargoenv.reports.to_time',
    description: '',
    defaultMessage: 'To time',
  },
  transport_buyers: {
    id: 'cargoenv.reports.transport_buyers',
    description: '',
    defaultMessage: 'Transport buyer(s)',
  },
  transport_buyers_selected: {
    id: 'cargoenv.reports.transport_buyers_selected',
    description: '',
    defaultMessage: '{amount} transport buyers selected',
  },
  transport_number_s: {
    id: 'cargoenv.reports.transport_numbers',
    description: '',
    defaultMessage: 'Transport number(s)',
  },
  transport_numbers_selected: {
    id: 'cargoenv.reports.transport_numbers_selected',
    description: '',
    defaultMessage: '{amount} transports selected',
  },
  transport_order_number_hashtag: {
    id: 'cargoenv.reports.transport_order_number_hashtag',
    description: '',
    defaultMessage: 'Transport order #',
  },
  transport_order_numbers: {
    id: 'cargoenv.reports.transport_order_numbers',
    description: '',
    defaultMessage: 'Transport order number(s)',
  },
  transport_order_numbers_selected: {
    id: 'cargoenv.reports.transport_order_numbers_selected',
    description: '',
    defaultMessage: '{amount} orders selected',
  },
  vehicles_selected: {
    id: 'cargoenv.reports.vehicles_selected',
    description: '',
    defaultMessage: '{amount} vehicles selected',
  },
  route_admin_description: {
    id: 'cargoenv.route_admin_description',
    description: '',
    defaultMessage: 'Create new routes and handle existing routes',
  },
  route_admin_label: {
    id: 'cargoenv.route_admin_label',
    description: '',
    defaultMessage: 'Route Administrator',
  },
  route_viewer_description: {
    id: 'cargoenv.route_viewer_description',
    description: '',
    defaultMessage: 'View existing routes',
  },
  route_viewer_label: {
    id: 'cargoenv.route_viewer_label',
    description: '',
    defaultMessage: 'Route Viewer',
  },
  create_route: {
    id: 'cargoenv.route.create_route',
    description: '',
    defaultMessage: 'Create route',
  },
  deleted_route: {
    id: 'cargoenv.route.deleted_route',
    description: '',
    defaultMessage: 'The route was deleted.',
  },
  destinations: {
    id: 'cargoenv.route.destinations',
    description: '',
    defaultMessage: 'Destinations',
  },
  error_deleting_route: {
    id: 'cargoenv.route.error_deleting_route',
    description: '',
    defaultMessage: 'Failed to delete route: ',
  },
  error_fetching_route_dot: {
    id: 'cargoenv.route.error_fetching_route',
    description: '',
    defaultMessage: 'Failed to fetch route.',
  },
  error_fetching_routes: {
    id: 'cargoenv.route.error_fetching_routes',
    description: '',
    defaultMessage: 'Failed to fetch routes',
  },
  error_saving_route: {
    id: 'cargoenv.route.error_saving_route',
    description: '',
    defaultMessage: 'Failed to save route.',
  },
  no_routes: {
    id: 'cargoenv.route.no_routes',
    description: '',
    defaultMessage: 'No routes',
  },
  no_routes_text: {
    id: 'cargoenv.route.no_routes_text',
    description: '',
    defaultMessage: "You don't have any routes yet. Start by creating one.",
  },
  setPassword: {
    id: 'cargoenv.setPassword.title',
    description: '',
    defaultMessage: 'Set Password',
  },
  settings_admin_description: {
    id: 'cargoenv.settings_admin_description',
    description: '',
    defaultMessage:
      'Handle B2B integrations, API names for carriers and change reference values for sustainability score',
  },
  settings_admin_label: {
    id: 'cargoenv.settings_admin_label',
    description: '',
    defaultMessage: 'Settings Administrator',
  },
  settings: {
    id: 'cargoenv.settings.title',
    description: '',
    defaultMessage: 'Settings',
  },
  addressLine1: {
    id: 'cargoenv.signup.addressLine1',
    description: '',
    defaultMessage: 'Address Line 1',
  },
  addressLine2: {
    id: 'cargoenv.signup.addressLine2',
    description: '',
    defaultMessage: 'Address Line 2',
  },
  auto_report_emissions_to_merchants: {
    id: 'cargoenv.signup.auto_report_emissions_to_merchants',
    description: '',
    defaultMessage: 'Automatically report my emissions to all my merchants',
  },
  auto_report_emissions_to_merchants_using: {
    id: 'cargoenv.signup.auto_report_emissions_to_merchants_using',
    description: '',
    defaultMessage: 'Automatically report emissions to merchants using',
  },
  type_automatically_collect_transport_data: {
    id: 'cargoenv.signup.automatically_collect_transport_data',
    description: '',
    defaultMessage: 'Automatically collect transport data from my fleet',
  },
  automatically_report_my_vehicles_emission_data_to_my_contractors_using: {
    id: 'cargoenv.signup.automatically_report_my_vehicles_emission_data_to_my_contractors_using',
    description: '',
    defaultMessage: 'Automatically report the emission data of my vehicles to my contractors using',
  },
  type_carrier_advanced: {
    id: 'cargoenv.signup.carrier_advanced',
    description: '',
    defaultMessage: 'Carrier Advanced',
  },
  type_carrier_advanced_description: {
    id: 'cargoenv.signup.carrier_advanced_description',
    description: '',
    defaultMessage: 'For transport companies contracted by goods owners',
  },
  type_carrier_freemium: {
    id: 'cargoenv.signup.carrier_freemium',
    description: '',
    defaultMessage: 'Carrier Freemium',
  },
  choose_country: {
    id: 'cargoenv.signup.choose_country',
    description: '',
    defaultMessage: 'Choose country',
  },
  chosen: {
    id: 'cargoenv.signup.chosen',
    description: '',
    defaultMessage: 'Chosen',
  },
  company_adress: {
    id: 'cargoenv.signup.company_address',
    description: '',
    defaultMessage: 'Company address',
  },
  company_signatory: {
    id: 'cargoenv.signup.company_signatory',
    description: '',
    defaultMessage: 'Company signatory',
  },
  company_signatory_checkbox_label: {
    id: 'cargoenv.signup.company_signatory_description',
    description: '',
    defaultMessage: 'I am the company signatory',
  },
  company_signatory_email: {
    id: 'cargoenv.signup.company_signatory_email',
    description: '',
    defaultMessage: 'Company signatory e-mail',
  },
  company_signatory_helper_text: {
    id: 'cargoenv.signup.company_signatory_helper_text',
    description: '',
    defaultMessage: 'Digital contract will be sent here',
  },
  company_signatory_phone_number: {
    id: 'cargoenv.signup.company_signatory_phone_number',
    description: '',
    defaultMessage: 'Company signatory phone number',
  },
  confirmation_if_questions_contact_us: {
    id: 'cargoenv.signup.confirmation_if_questions_contact_us',
    description: '',
    defaultMessage:
      "If you have any questions regarding your account or Teralo in general don't hesitate to contact us.",
  },
  confirmation_request_being_handled_by: {
    id: 'cargoenv.signup.confirmation_request_being_handled_by',
    description: '',
    defaultMessage: 'is now being handled by Teralo team.',
  },
  confirmation_request_with_email: {
    id: 'cargoenv.signup.confirmation_request_with_email',
    description: '',
    defaultMessage: 'Your confirmation request with e-mail:',
  },
  confirmation_sent_title: {
    id: 'cargoenv.signup.confirmation_sent_title',
    description: '',
    defaultMessage: 'Registration sent',
  },
  contact_person_first_name: {
    id: 'cargoenv.signup.contact_person_first_name',
    description: '',
    defaultMessage: 'Contact person - First name',
  },
  contact_person_last_name: {
    id: 'cargoenv.signup.contact_person_last_name',
    description: '',
    defaultMessage: 'Contact person - Last name',
  },
  contact_support: {
    id: 'cargoenv.signup.contact_support',
    description: '',
    defaultMessage:
      "If you have any questions about how to fill in the form or if you're not able to complete the form, don't hesitate to contact the Teralo support:",
  },
  email_digital_contract_info: {
    id: 'cargoenv.signup.email_digital_contract_info',
    description: '',
    defaultMessage:
      'You will get a separate e-mail with the contract that is required to digitally sign before you get your login credentials',
  },
  error_sending_request: {
    id: 'cargoenv.signup.error_sending_request',
    description: '',
    defaultMessage: 'Could not send registration request',
  },
  type_get_emission_data_from_connected_subcarriers_and_own_fleet: {
    id: 'cargoenv.signup.get_emission_data_from_connected_subcarriers_and_own_fleet',
    description: '',
    defaultMessage: 'Get emission data from my connected sub-carriers & own fleet',
  },
  contact_info: {
    id: 'cargoenv.signup.header_contact_info',
    description: '',
    defaultMessage: 'Contact information',
  },
  header_organisation_helper_text: {
    id: 'cargoenv.signup.header_organisation_helper_text',
    description: '',
    defaultMessage: 'What is your business?',
  },
  header_please_select_plan: {
    id: 'cargoenv.signup.header_please_select_plan',
    description: '',
    defaultMessage: 'Please select a plan that matches your business',
  },
  header_what_do_you_do: {
    id: 'cargoenv.signup.header_what_do_you_do',
    description: '',
    defaultMessage: 'What do you do?',
  },
  invalid_email: {
    id: 'cargoenv.signup.invalid_email',
    description: '',
    defaultMessage: 'Invalid e-mail',
  },
  invoicing_checkbox_label: {
    id: 'cargoenv.signup.invoicing_checkbox_label',
    description: '',
    defaultMessage: 'I am the invoicing responsible',
  },
  invoicing_title: {
    id: 'cargoenv.signup.invoicing_title',
    description: '',
    defaultMessage: 'Invoicing',
  },
  need_support: {
    id: 'cargoenv.signup.need_support',
    description: '',
    defaultMessage: 'Need support?',
  },
  next_button_label: {
    id: 'cargoenv.signup.next_button_label',
    description: '',
    defaultMessage: 'Next step',
  },
  not_found: {
    id: 'cargoenv.signup.not_found',
    description: '',
    defaultMessage: "Can't find what you are looking for?",
  },
  not_found_description: {
    id: 'cargoenv.signup.not_found_description',
    description: '',
    defaultMessage: "If you can't find the suitable plan for you, contact Teralo and we'll help you.",
  },
  please_confirm_info_correct: {
    id: 'cargoenv.signup.please_confirm_info_correct',
    description: '',
    defaultMessage: 'Please confirm that the following information is correct.',
  },
  postal_code: {
    id: 'cargoenv.signup.postal_code',
    description: '',
    defaultMessage: 'Postal code',
  },
  privacy_notice_pdf_link_label: {
    id: 'cargoenv.signup.privacy_notice_pdf_link_label',
    description: '',
    defaultMessage: 'Privacy Notice (PDF)',
  },
  send_reqistration: {
    id: 'cargoenv.signup.send_send_reqistration',
    description: '',
    defaultMessage: 'Send confirmation request',
  },
  type_sub_carrier_freemium: {
    id: 'cargoenv.signup.sub_carrier_freemium',
    description: '',
    defaultMessage: 'Sub-carrier Freemium',
  },
  type_for_transport_companies_contracted_by_carrier_companies_using: {
    id: 'cargoenv.signup.sub_carrier_freemium_description',
    description: '',
    defaultMessage: 'For transport companies contracted by carrier companies using',
  },
  teralo_terms_of_use: {
    id: 'cargoenv.signup.teralo_terms_of_use',
    description: '',
    defaultMessage: 'Teralo Terms of Use',
  },
  terms_of_use_pdf_link_label: {
    id: 'cargoenv.signup.terms_of_use_pdf_link_label',
    description: '',
    defaultMessage: 'Terms of Use (PDF)',
  },
  type_for_transport_companies_contracted_by_goods_owners_using: {
    id: 'cargoenv.signup.transport_companies_contracted_by_goods_owners',
    description: '',
    defaultMessage: 'For transport companies contracted by goods owners using',
  },
  type_merchant_description: {
    id: 'cargoenv.signup.type_merchant_description',
    description: '',
    defaultMessage: 'For companies that buys transports for their goods',
  },
  type_get_emission_data_from_carriers: {
    id: 'cargoenv.signup.type_merchant_emission_data_from_carriers',
    description: '',
    defaultMessage: 'Get emission data from my connected carriers',
  },
  type_view_emissions_dashboard_reports: {
    id: 'cargoenv.signup.type_merchant_view_emissions_dashboard_reports',
    description: '',
    defaultMessage: 'View emissions in dashboard and reports',
  },
  type_view_emissions_dashboard_reports_with_dayily_resolution: {
    id: 'cargoenv.signup.view_emissions_dashboard_reports_with_dayily_resolution',
    description: '',
    defaultMessage: 'View emissions in dashboard and reports with daily resolution',
  },
  view_emissions_in_dashboard_from_my_own_vehicles: {
    id: 'cargoenv.signup.view_emissions_in_dashboard_from_my_own_vehicles',
    description: '',
    defaultMessage: 'View emissions in dashboard from my own vehicles',
  },
  view_emissions_in_dashboard_with_monthly_resolution: {
    id: 'cargoenv.signup.view_emissions_in_dashboard_with_monthly_resolution',
    description: '',
    defaultMessage: 'View emissions in dashboard with monthly resolution',
  },
  will_confirm_when_acount_active: {
    id: 'cargoenv.signup.will_confirm_when_acount_active',
    description: '',
    defaultMessage: 'We will let you know when the account is active and ready to be used.',
  },
  subcarrier_dashboard_description: {
    id: 'cargoenv.subcarrier_dashboard_description',
    description: '',
    defaultMessage: 'View and export information about CO2 Emissions and Energy Mix',
  },
  subcarrier_dashboard_label: {
    id: 'cargoenv.subcarrier_dashboard_label',
    description: '',
    defaultMessage: 'Widget Dashboard',
  },
  add_value: {
    id: 'cargoenv.sustainability_scores.add_value',
    description: '',
    defaultMessage: 'Add value',
  },
  help_text_scores: {
    id: 'cargoenv.sustainability_scores.help_text_scores',
    description: '',
    defaultMessage:
      'The sustainability score highlights CO₂e footprints for completed transport legs and can be adjusted by a settings administrator. Results are visible in the {link} for all users with report authorization. To adjust the levels for the 1-5 score segments, click on the numbers below each score level and then click "Save Scores" for your changes to take effect.',
  },
  infinity: {
    id: 'cargoenv.sustainability_scores.infinity',
    description: '',
    defaultMessage: 'Infinity',
  },
  restore_default_scores: {
    id: 'cargoenv.sustainability_scores.restore_default_scores',
    description: '',
    defaultMessage: 'Restore default scores',
  },
  save_scores: {
    id: 'cargoenv.sustainability_scores.save',
    description: '',
    defaultMessage: 'Save scores',
  },
  save_failed: {
    id: 'cargoenv.sustainability_scores.save_failed',
    description: '',
    defaultMessage: 'Something went wrong',
  },
  score_acute: {
    id: 'cargoenv.sustainability_scores.score_acute',
    description: '',
    defaultMessage: 'Acute',
  },
  score_bad: {
    id: 'cargoenv.sustainability_scores.score_bad',
    description: '',
    defaultMessage: 'Bad',
  },
  score_good: {
    id: 'cargoenv.sustainability_scores.score_good',
    description: '',
    defaultMessage: 'Good',
  },
  score_great: {
    id: 'cargoenv.sustainability_scores.score_great',
    description: '',
    defaultMessage: 'Great',
  },
  transport_admin_description: {
    id: 'cargoenv.transport_admin_description',
    description: '',
    defaultMessage: 'Create new and handle existing transports',
  },
  transport_admin_label: {
    id: 'cargoenv.transport_admin_label',
    description: '',
    defaultMessage: 'Transport Administrator',
  },
  transport_order_admin_description: {
    id: 'cargoenv.transport_order_admin_description',
    description: '',
    defaultMessage: 'Create new and handle existing transport orders',
  },
  transport_order_admin_label: {
    id: 'cargoenv.transport_order_admin_label',
    description: '',
    defaultMessage: 'Transport Order Administrator',
  },
  transport_order_viewer_description: {
    id: 'cargoenv.transport_order_viewer_description',
    description: '',
    defaultMessage: 'View existing transport orders',
  },
  transport_order_viewer_label: {
    id: 'cargoenv.transport_order_viewer_label',
    description: '',
    defaultMessage: 'Transport Order Viewer',
  },
  assigned_carrier: {
    id: 'cargoenv.transport_order.importer.assigned_carrier',
    description: '',
    defaultMessage: 'Assigned carrier',
  },
  default_carrier: {
    id: 'cargoenv.transport_order.importer.default_carrier',
    description: '',
    defaultMessage: 'Default Carrier',
  },
  available_imported_orders: {
    id: 'cargoenv.transport_order.importer.dialog_title',
    description: '',
    defaultMessage: 'Available imported orders',
  },
  display_preview_error_transport_orders: {
    id: 'cargoenv.transport_order.importer.display_preview_error',
    description: '',
    defaultMessage: 'Cannot display preview of imported transport orders. Check if file contains correct data.',
  },
  import_error_transport_orders: {
    id: 'cargoenv.transport_order.importer.import_error',
    description: '',
    defaultMessage: 'Cannot import transport orders. Check if file contains correct data.',
  },
  import_successful_transport_orders: {
    id: 'cargoenv.transport_order.importer.import_successful',
    description: '',
    defaultMessage: 'Import successful, {count} transport orders was saved.',
  },
  loading_prerequisites: {
    id: 'cargoenv.transport_order.importer.loading_prerequisites',
    description: '',
    defaultMessage: 'Unable to import files because available carriers or business types could not be loaded',
  },
  no_transport_orders_to_import: {
    id: 'cargoenv.transport_order.importer.no_transport_orders',
    description: '',
    defaultMessage: 'No transport orders to import.',
  },
  order_items_with_count: {
    id: 'cargoenv.transport_order.importer.order_items_with_count',
    description: '',
    defaultMessage: '{count} order items',
  },
  upload_status_importing_transport_orders: {
    id: 'cargoenv.transport_order.importer.upload_status_importing',
    description: '',
    defaultMessage: 'Storing transport orders...',
  },
  upload_status_loading_preview_transport_orders: {
    id: 'cargoenv.transport_order.importer.upload_status_loading_preview',
    description: '',
    defaultMessage: 'Loading preview of transport orders...',
  },
  transport_viewer_description: {
    id: 'cargoenv.transport_viewer_description',
    description: '',
    defaultMessage: 'View existing transports',
  },
  transport_viewer_label: {
    id: 'cargoenv.transport_viewer_label',
    description: '',
    defaultMessage: 'Transport Viewer',
  },
  assigned_vehicle_none_selected: {
    id: 'cargoenv.transport.assigned_vehicle_none_selected',
    description: '',
    defaultMessage: 'No vehicle selected',
  },
  select_a_vehicle: {
    id: 'cargoenv.transport.assigned_vehicle_placeholder',
    description: '',
    defaultMessage: 'Select a vehicle',
  },
  confirm_remove_transport_order: {
    id: 'cargoenv.transport.confirm_remove_transport_order',
    description: '',
    defaultMessage: 'Are you sure you want to remove this transport order from the transport?',
  },
  create_transport: {
    id: 'cargoenv.transport.create_transport',
    description: '',
    defaultMessage: 'Create transport',
  },
  delete_transport: {
    id: 'cargoenv.transport.delete_transport',
    description: '',
    defaultMessage: 'Delete transport',
  },
  delete_transport_dialog: {
    id: 'cargoenv.transport.delete_transport_dialog',
    description: '',
    defaultMessage: 'Are you sure you want to delete this transport, it can not be undone?',
  },
  deleted_transport: {
    id: 'cargoenv.transport.deleted_transport',
    description: '',
    defaultMessage: 'The transport was deleted.',
  },
  completed_time: {
    id: 'cargoenv.transport.end_time',
    description: '',
    defaultMessage: 'Completed time',
  },
  error_deleting_transport: {
    id: 'cargoenv.transport.error_deleting_transport',
    description: '',
    defaultMessage: 'Failed to delete transport: ',
  },
  error_fetching_transport: {
    id: 'cargoenv.transport.error_fetching_transport',
    description: '',
    defaultMessage: 'Failed to fetch transport.',
  },
  error_saving_transport: {
    id: 'cargoenv.transport.error_saving_transport',
    description: '',
    defaultMessage: 'Failed to save transport.',
  },
  assigned_vehicle: {
    id: 'cargoenv.transport.importer.assigned_vehicle',
    description: '',
    defaultMessage: 'Assigned vehicle',
  },
  business_label: {
    id: 'cargoenv.transport.importer.business_label',
    description: '',
    defaultMessage: 'Business label',
  },
  completed: {
    id: 'cargoenv.transport.importer.completed',
    description: '',
    defaultMessage: 'Completed',
  },
  default_fuel_quality: {
    id: 'cargoenv.transport.importer.default_fuel_quality',
    description: '',
    defaultMessage: 'Default Fuel Quality',
  },
  default_vehicle: {
    id: 'cargoenv.transport.importer.default_vehicle',
    description: '',
    defaultMessage: 'Default Vehicle',
  },
  available_imported_transports: {
    id: 'cargoenv.transport.importer.dialog_title',
    description: '',
    defaultMessage: 'Available imported transports',
  },
  display_preview_error_transports: {
    id: 'cargoenv.transport.importer.display_preview_error',
    description: '',
    defaultMessage: 'Cannot display preview of imported transports. Check if file contains correct data.',
  },
  full_vehicle_weight: {
    id: 'cargoenv.transport.importer.full_vehicle_weight',
    description: '',
    defaultMessage: 'Full vehicle weight',
  },
  ignore_no_orders: {
    id: 'cargoenv.transport.importer.ignore_no_orders',
    description: '',
    defaultMessage: 'Ignore transports without transport orders',
  },
  ignore_no_vehicle: {
    id: 'cargoenv.transport.importer.ignore_no_vehicle',
    description: '',
    defaultMessage: 'Ignore transports without assigned vehicle',
  },
  import_error_transports: {
    id: 'cargoenv.transport.importer.import_error',
    description: '',
    defaultMessage: 'Cannot import transports. Check if file contains correct data.',
  },
  import_successful_transports: {
    id: 'cargoenv.transport.importer.import_successful',
    description: '',
    defaultMessage: 'Import successful, {count} transports was saved.',
  },
  loading_vehicles_error: {
    id: 'cargoenv.transport.importer.loading_vehicles_error',
    description: '',
    defaultMessage: 'Unable to import files. Vehicles could not be loaded.',
  },
  no_transports_to_import: {
    id: 'cargoenv.transport.importer.no_transports',
    description: '',
    defaultMessage: 'No transports to import.',
  },
  route_name: {
    id: 'cargoenv.transport.importer.route_name',
    description: '',
    defaultMessage: 'Route name',
  },
  show_preview: {
    id: 'cargoenv.transport.importer.show_preview',
    description: '',
    defaultMessage: 'Show Preview',
  },
  total_goods_volume: {
    id: 'cargoenv.transport.importer.total_goods_volume',
    description: '',
    defaultMessage: 'Total goods volume',
  },
  transport_orders_with_count: {
    id: 'cargoenv.transport.importer.transport_orders_with_count',
    description: '',
    defaultMessage: '{count} orders',
  },
  uncompleted: {
    id: 'cargoenv.transport.importer.uncompleted',
    description: '',
    defaultMessage: 'Uncompleted',
  },
  update_preview: {
    id: 'cargoenv.transport.importer.update_preview',
    description: '',
    defaultMessage: 'Update Preview',
  },
  upload_status_importing_transports: {
    id: 'cargoenv.transport.importer.upload_status_importing',
    description: '',
    defaultMessage: 'Storing transports...',
  },
  upload_status_loading_preview_transports: {
    id: 'cargoenv.transport.importer.upload_status_loading_preview',
    description: '',
    defaultMessage: 'Loading preview of transports...',
  },
  no_transports: {
    id: 'cargoenv.transport.no_transports',
    description: '',
    defaultMessage: 'No transports',
  },
  no_transports_text: {
    id: 'cargoenv.transport.no_transports_text',
    description: '',
    defaultMessage: "You don't have any transports yet. Start by creating one or import a transport file",
  },
  no_transports_text_no_import: {
    id: 'cargoenv.transport.no_transports_text_no_import',
    description: '',
    defaultMessage: "You don't have any transports yet. Start by creating one",
  },
  number_colon: {
    id: 'cargoenv.transport.number_colon',
    description: '',
    defaultMessage: 'Number:',
  },
  rail: {
    id: 'cargoenv.transport.rail',
    description: '',
    defaultMessage: 'Rail',
  },
  remove_transport_order: {
    id: 'cargoenv.transport.remove_transport_order',
    description: '',
    defaultMessage: 'Remove transport order',
  },
  road: {
    id: 'cargoenv.transport.road',
    description: '',
    defaultMessage: 'Road',
  },
  select_a_route: {
    id: 'cargoenv.transport.route_placeholder',
    description: '',
    defaultMessage: 'Select a route',
  },
  save_transport: {
    id: 'cargoenv.transport.save_transport',
    description: '',
    defaultMessage: 'Save transport',
  },
  save_transport_dialog: {
    id: 'cargoenv.transport.save_transport_dialog',
    description: '',
    defaultMessage: 'Your transport has unsaved changes. If you want to keep your changes, select save and close. ',
  },
  start_time: {
    id: 'cargoenv.transport.start_time',
    description: '',
    defaultMessage: 'Start time',
  },
  total_cargo_weight: {
    id: 'cargoenv.transport.total_cargo_weight',
    description: '',
    defaultMessage: 'Total cargo weight (kg)',
  },
  total_goods_volume_cd3: {
    id: 'cargoenv.transport.total_goods_volume',
    description: '',
    defaultMessage: 'Total goods volume (CD3)',
  },
  transport_information: {
    id: 'cargoenv.transport.transport_information',
    description: '',
    defaultMessage: 'Transport information',
  },
  transport_no: {
    id: 'cargoenv.transport.transport_no',
    description: '',
    defaultMessage: 'Transport no.',
  },
  transport_number: {
    id: 'cargoenv.transport.transport_number',
    description: '',
    defaultMessage: 'Transport number',
  },
  select_transport_orders: {
    id: 'cargoenv.transport.transport_orders_placeholder',
    description: '',
    defaultMessage: 'Select transport order(s)',
  },
  transport_orders_selected: {
    id: 'cargoenv.transport.transport_orders_selected',
    description: '',
    defaultMessage: '{amount} transport orders selected',
  },
  vehicle_data_for_transport: {
    id: 'cargoenv.transport.vehicle_data',
    description: '',
    defaultMessage: 'Vehicle data for transport',
  },
  vehicle_data_energy_used_in_kwh: {
    id: 'cargoenv.transport.vehicle_data_energy_used_in_kwh',
    description: '',
    defaultMessage: 'Energy used (kWh)',
  },
  vehicle_data_fuel_quality: {
    id: 'cargoenv.transport.vehicle_data_fuel_quality',
    description: '',
    defaultMessage: 'Fuel quality for assigned vehicle',
  },
  vehicle_data_fuel_quality_placeholder: {
    id: 'cargoenv.transport.vehicle_data_fuel_quality_placeholder',
    description: '',
    defaultMessage: 'Select a fuel quality',
  },
  vehicle_data_fuel_type: {
    id: 'cargoenv.transport.vehicle_data_fuel_type',
    description: '',
    defaultMessage: 'Fuel type for assigned vehicle',
  },
  vehicle_data_fuel_type_unset: {
    id: 'cargoenv.transport.vehicle_data_fuel_type_unset',
    description: '',
    defaultMessage: 'No fuel type set',
  },
  vehicle_data_km_driven: {
    id: 'cargoenv.transport.vehicle_data_km_driven',
    description: '',
    defaultMessage: 'km driven',
  },
  vehicle_data_used_fuel_in_litres: {
    id: 'cargoenv.transport.vehicle_data_used_fuel_in_litres',
    description: '',
    defaultMessage: 'Used fuel (litres)',
  },
  transportBuyerCustomReports: {
    id: 'cargoenv.transportBuyerCustomReports',
    description: '',
    defaultMessage: 'Transport order reports (buyer)',
  },
  transportBuyerCustomTransportReports: {
    id: 'cargoenv.transportBuyerCustomTransportReports',
    description: '',
    defaultMessage: 'Transport reports (buyer)',
  },
  transportBuyerReports: {
    id: 'cargoenv.transportBuyerReports',
    description: '',
    defaultMessage: 'Reports (buyer)',
  },
  transporterCustomReports: {
    id: 'cargoenv.transporterCustomReports',
    description: '',
    defaultMessage: 'Transport order reports (carrier)',
  },
  transporterCustomTransportReports: {
    id: 'cargoenv.transporterCustomTransportReports',
    description: '',
    defaultMessage: 'Transport reports (carrier)',
  },
  transporterReports: {
    id: 'cargoenv.transporterReports',
    description: '',
    defaultMessage: 'Reports (carrier)',
  },
  assigned_carriers: {
    id: 'cargoenv.transportOrder.assigned_carriers',
    description: '',
    defaultMessage: 'Assigned carriers',
  },
  assigned_carriers_placeholder: {
    id: 'cargoenv.transportOrder.assigned_carriers_placeholder',
    description: '',
    defaultMessage: 'Choose carrier(s)',
  },
  business_type: {
    id: 'cargoenv.transportOrder.business_type',
    description: '',
    defaultMessage: 'Business type',
  },
  confirm_delete_transport_order: {
    id: 'cargoenv.transportOrder.confirm_delete_transport_order',
    description: '',
    defaultMessage: 'Are you sure you want to delete this transport order?',
  },
  create_order_item: {
    id: 'cargoenv.transportOrder.create_order_item',
    description: '',
    defaultMessage: 'Create order item',
  },
  create_transport_order: {
    id: 'cargoenv.transportOrder.create_transport_order',
    description: '',
    defaultMessage: 'Create transport order',
  },
  current_order_reference: {
    id: 'cargoenv.transportOrder.current_order_reference',
    description: '',
    defaultMessage: 'Current order reference',
  },
  delete_transport_order: {
    id: 'cargoenv.transportOrder.delete_transport_order',
    description: '',
    defaultMessage: 'Delete transport order',
  },
  deleted_transport_order: {
    id: 'cargoenv.transportOrder.deleted_transport_order',
    description: '',
    defaultMessage: 'The transport order was deleted.',
  },
  delivered_time: {
    id: 'cargoenv.transportOrder.delivered_time',
    description: '',
    defaultMessage: 'Delivered time',
  },
  desired_times: {
    id: 'cargoenv.transportOrder.desired_times',
    description: '',
    defaultMessage: 'Desired times',
  },
  error_deleting_transport_order: {
    id: 'cargoenv.transportOrder.error_deleting_transport_order',
    description: '',
    defaultMessage: 'Failed to delete transport order: ',
  },
  error_fetching_order_item: {
    id: 'cargoenv.transportOrder.error_fetching_order_item',
    description: '',
    defaultMessage: 'Could not get order item data.',
  },
  failed_fetching_transport_item_types: {
    id: 'cargoenv.transportOrder.error_fetching_transport_item_types',
    description: '',
    defaultMessage: 'Failed to fetch transport item types',
  },
  error_fetching_transport_order: {
    id: 'cargoenv.transportOrder.error_fetching_transport_order',
    description: '',
    defaultMessage: 'Error when fetching transport order.',
  },
  error_fetching_transport_order_types: {
    id: 'cargoenv.transportOrder.error_fetching_transport_order_types',
    description: '',
    defaultMessage: 'Failed to fetch transport order types',
  },
  error_saving_transport_order: {
    id: 'cargoenv.transportOrder.error_saving_transport_order',
    description: '',
    defaultMessage: 'Failed to save transport order.',
  },
  goods_type: {
    id: 'cargoenv.transportOrder.goods_type',
    description: '',
    defaultMessage: 'Goods type',
  },
  import_transport_order: {
    id: 'cargoenv.transportOrder.import_transport_order',
    description: '',
    defaultMessage: 'Import transport order',
  },
  loading_carriers: {
    id: 'cargoenv.transportOrder.loading_carriers',
    description: '',
    defaultMessage: 'Loading carriers...',
  },
  no_available_carriers: {
    id: 'cargoenv.transportOrder.no_available_carriers',
    description: '',
    defaultMessage: 'No available carriers',
  },
  no_available_routes: {
    id: 'cargoenv.transportOrder.no_available_routes',
    description: '',
    defaultMessage: 'No available routes',
  },
  no_of_items: {
    id: 'cargoenv.transportOrder.no_of_items',
    description: '',
    defaultMessage: 'Number of items',
  },
  no_transport_orders_text: {
    id: 'cargoenv.transportOrder.no_transport_orders_text',
    description: '',
    defaultMessage: "You don't have any transport orders yet. Start by creating one or import a transport orders file",
  },
  order_item: {
    id: 'cargoenv.transportOrder.order_item',
    description: '',
    defaultMessage: 'Order item',
  },
  order_items: {
    id: 'cargoenv.transportOrder.order_items',
    description: '',
    defaultMessage: 'Order items',
  },
  order_reference: {
    id: 'cargoenv.transportOrder.order_reference',
    description: '',
    defaultMessage: 'Customer order reference',
  },
  order_number: {
    id: 'cargoenv.transportOrder.ordernumber',
    description: '',
    defaultMessage: 'Order number',
  },
  pickup_time: {
    id: 'cargoenv.transportOrder.pickup_time',
    description: '',
    defaultMessage: 'Pickup time',
  },
  refresh: {
    id: 'cargoenv.transportOrder.refresh',
    description: '',
    defaultMessage: 'Refresh',
  },
  save_order_item: {
    id: 'cargoenv.transportOrder.save_order_item',
    description: '',
    defaultMessage: 'Save order item',
  },
  service_level: {
    id: 'cargoenv.transportOrder.service_level',
    description: '',
    defaultMessage: 'Service level',
  },
  shipment_tag: {
    id: 'cargoenv.transportOrder.shipment_tag',
    description: '',
    defaultMessage: 'Shipment tag',
  },
  shipment_tag_text: {
    id: 'cargoenv.transportOrder.shipment_tag_text',
    description: '',
    defaultMessage: 'Use this to group transport orders that went on the same vehicle and route',
  },
  total_volume_cd3: {
    id: 'cargoenv.transportOrder.total_volume_cd3',
    description: '',
    defaultMessage: 'Total order volume (CD³/dm³/liter)',
  },
  total_weight_kg: {
    id: 'cargoenv.transportOrder.total_weight_kg',
    description: '',
    defaultMessage: 'Total order weight (kg)',
  },
  transport_item_type: {
    id: 'cargoenv.transportOrder.transport_item_type',
    description: '',
    defaultMessage: 'Transport item type',
  },
  transport_order: {
    id: 'cargoenv.transportOrder.transport_order',
    description: '',
    defaultMessage: 'Transport order',
  },
  transport_order_details: {
    id: 'cargoenv.transportOrder.transport_order_details',
    description: '',
    defaultMessage: 'Transport order details',
  },
  transport_order_number: {
    id: 'cargoenv.transportOrder.transport_order_number',
    description: '',
    defaultMessage: 'Transport order number',
  },
  transport_order_type: {
    id: 'cargoenv.transportOrder.transport_order_type',
    description: '',
    defaultMessage: 'Transport order type',
  },
  transport_order_unsaved_changes: {
    id: 'cargoenv.transportOrder.unsaved_changes',
    description: '',
    defaultMessage:
      'Your transport order has unsaved changes. If you want to keep your changes, select save and close. ',
  },
  volume_cd3: {
    id: 'cargoenv.transportOrder.volume_cd3',
    description: '',
    defaultMessage: 'Volume (CD³/dm³/liter)',
  },
  weight_kg: {
    id: 'cargoenv.transportOrder.weight_kg',
    description: '',
    defaultMessage: 'Weight (kg)',
  },
  litre: {
    id: 'cargoenv.units.litre',
    description: '',
    defaultMessage: 'litre',
  },
  thu: {
    id: 'cargoenv.units.thu',
    description: '',
    defaultMessage: 'THU (transport handling unit)',
  },
  user_admin_description: {
    id: 'cargoenv.user_admin_description',
    description: '',
    defaultMessage: 'Handle organization users and remove users from the organization',
  },
  user_admin_label: {
    id: 'cargoenv.user_admin_label',
    description: '',
    defaultMessage: 'User Administrator',
  },
  error_getting_organizations: {
    id: 'cargoenv.user_menu.error_getting_organizations',
    description: '',
    defaultMessage: 'Error getting organizations',
  },
  error_switch_organisation: {
    id: 'cargoenv.user_menu.error_switch_organisation',
    description: '',
    defaultMessage: 'Could not switch to this organisation',
  },
  not_activated: {
    id: 'cargoenv.user_menu.not_activated',
    description: '',
    defaultMessage: 'Not activated',
  },
  sign_out: {
    id: 'cargoenv.user_menu.sign_out',
    description: '',
    defaultMessage: 'Sign out',
  },
  switch_organisation: {
    id: 'cargoenv.user_menu.switch_organisation',
    description: '',
    defaultMessage: 'Switch organisation',
  },
  user_menu: {
    id: 'cargoenv.user_menu.title',
    description: '',
    defaultMessage: 'User menu',
  },
  account_details: {
    id: 'cargoenv.user.account_details',
    description: '',
    defaultMessage: 'Account details',
  },
  edit_information: {
    id: 'cargoenv.user.edit_information',
    description: '',
    defaultMessage: 'Edit information',
  },
  emptyApiStoreResponse: {
    id: 'cargoenv.user.emptyApiStoreResponse',
    description: '',
    defaultMessage: 'Empty Api Store Response',
  },
  error_fetching_user: {
    id: 'cargoenv.user.error_fetching_user',
    description: '',
    defaultMessage: 'Could not fetch user data',
  },
  error_saving_user: {
    id: 'cargoenv.user.error_saving_user',
    description: '',
    defaultMessage: 'Error while saving user',
  },
  error_saving_user_already_exists: {
    id: 'cargoenv.user.error_saving_user_already_exists',
    description: '',
    defaultMessage: 'A user with that email already exist',
  },
  error_update_attributes: {
    id: 'cargoenv.user.my_account.error_update_attributes',
    description: '',
    defaultMessage: 'Could not update user attributes',
  },
  login_credentials: {
    id: 'cargoenv.user.my_account.login_credentials',
    description: '',
    defaultMessage: 'Login credentials',
  },
  new_password: {
    id: 'cargoenv.user.my_account.new_password',
    description: '',
    defaultMessage: 'New password',
  },
  old_password: {
    id: 'cargoenv.user.my_account.old_password',
    description: '',
    defaultMessage: 'Old password',
  },
  password_colon: {
    id: 'cargoenv.user.my_account.password',
    description: '',
    defaultMessage: 'Password:',
  },
  no_users: {
    id: 'cargoenv.user.no_users',
    description: '',
    defaultMessage: 'No Users',
  },
  no_users_text: {
    id: 'cargoenv.user.no_users_text',
    description: '',
    defaultMessage: 'No Users found',
  },
  user_not_removed: {
    id: 'cargoenv.user.not_removed',
    description: '',
    defaultMessage: 'User could not be removed',
  },
  ok_question: {
    id: 'cargoenv.user.ok',
    description: '',
    defaultMessage: 'Ok?',
  },
  remove_user: {
    id: 'cargoenv.user.remove',
    description: '',
    defaultMessage: 'Remove user',
  },
  user_removed: {
    id: 'cargoenv.user.removed',
    description: '',
    defaultMessage: 'User removed',
  },
  request_new_password: {
    id: 'cargoenv.user.request_new_password',
    description: '',
    defaultMessage: 'Request new password',
  },
  saved_user: {
    id: 'cargoenv.user.saved_user',
    description: '',
    defaultMessage: 'User is now saved',
  },
  signout_info: {
    id: 'cargoenv.user.signout_info',
    description: '',
    defaultMessage: 'For the roles to get updated we need to sign you out.',
  },
  type_of_role: {
    id: 'cargoenv.user.type_of_role',
    description: '',
    defaultMessage: 'Type of role',
  },
  user_invited: {
    id: 'cargoenv.user.user_invited',
    description: '',
    defaultMessage: 'User invited',
  },
  users_tab_name: {
    id: 'cargoenv.user.users_tab_name',
    description: '',
    defaultMessage: 'All users',
  },
  filter_user: {
    id: 'cargoenv.users.filter_user',
    description: '',
    defaultMessage: 'Filter user',
  },
  search_user: {
    id: 'cargoenv.users.search_user',
    description: '',
    defaultMessage: 'Search user',
  },
  vehicle_admin_description: {
    id: 'cargoenv.vehicle_admin_description',
    description: '',
    defaultMessage: 'Create and connect new vehicles and handle existing vehicles',
  },
  vehicle_admin_label: {
    id: 'cargoenv.vehicle_admin_label',
    description: '',
    defaultMessage: 'Vehicle Administrator',
  },
  vehicle_viewer_description: {
    id: 'cargoenv.vehicle_viewer_description',
    description: '',
    defaultMessage: 'View information about existing vehicles',
  },
  vehicle_viewer_label: {
    id: 'cargoenv.vehicle_viewer_label',
    description: '',
    defaultMessage: 'Vehicle Viewer',
  },
  file_information: {
    id: 'cargoenv.vehicle.file_information',
    description: '',
    defaultMessage: 'File information',
  },
  upload_file_error: {
    id: 'cargoenv.vehicle.upload_file_error',
    description: '',
    defaultMessage:
      'We found {count, plural, one {# error in your file. This row will not be uploaded} other {# errors in your file. These rows will not be uploaded}}',
  },
  upload_file_text: {
    id: 'cargoenv.vehicle.upload_file_text',
    description: '',
    defaultMessage: 'Start by uploading your file and check for potential connections.',
  },
  upload_file_warning: {
    id: 'cargoenv.vehicle.upload_file_warning',
    description: '',
    defaultMessage:
      'We found {count, plural, one { this # mismatch} other {these # mismatches}} in your file. You will still be able to upload the file but you need to complete your vehicle setup in a later stage.',
  },
  add_another_vehicle: {
    id: 'cargoenv.vehicles.add_another_vehicle',
    description: '',
    defaultMessage: 'Add another vehicle',
  },
  add_train: {
    id: 'cargoenv.vehicles.add_train',
    description: '',
    defaultMessage: 'Add train',
  },
  add_vehicle: {
    id: 'cargoenv.vehicles.add_vehicle',
    description: '',
    defaultMessage: 'Add vehicle',
  },
  add_vehicle_estimate_warning: {
    id: 'cargoenv.vehicles.add_vehicle_estimate_warning',
    description: '',
    defaultMessage:
      'You are about to add a vehicle without a proper connection. The vehicle will only deliver estimated values. You need to add a connection to be able to collect real data.',
  },
  add_no_of_vehicles: {
    id: 'cargoenv.vehicles.add_vehicles',
    description: '',
    defaultMessage: 'Add {count, plural, one {# vehicle} other {# vehicles} }',
  },
  add_with_file: {
    id: 'cargoenv.vehicles.add_with_file',
    description: '',
    defaultMessage: 'Add vehicles through file',
  },
  add_with_file_text: {
    id: 'cargoenv.vehicles.add_with_file_text',
    description: '',
    defaultMessage:
      'Choose this option if you have several vehicles to add and want to search for vehicle information by uploading a csv file.',
  },
  all_vehicles: {
    id: 'cargoenv.vehicles.all_vehicles',
    description: '',
    defaultMessage: 'All vehicles',
  },
  are_you_sure_estimate: {
    id: 'cargoenv.vehicles.are_you_sure_estimate',
    description: '',
    defaultMessage: 'Are you sure you want to add the vehicle without a connection?',
  },
  articulated_truck: {
    id: 'cargoenv.vehicles.articulated_truck',
    description: '',
    defaultMessage: 'Articulated truck/tractor',
  },
  articulated_truck_text: {
    id: 'cargoenv.vehicles.articulated_truck_text',
    description: '',
    defaultMessage: 'Road vehicle towing a trailer including a semi-trailer',
  },
  available_proxies: {
    id: 'cargoenv.vehicles.available_proxies',
    description: '',
    defaultMessage: 'Available proxies',
  },
  basic: {
    id: 'cargoenv.vehicles.basic',
    description: '',
    defaultMessage: 'Basic',
  },
  basic_text: {
    id: 'cargoenv.vehicles.basic_text',
    description: '',
    defaultMessage: 'This information is required for saving a vehicle',
  },
  check_connection: {
    id: 'cargoenv.vehicles.check_connection',
    description: '',
    defaultMessage: 'Check connection',
  },
  check_connection_dialog: {
    id: 'cargoenv.vehicles.check_connection_dialog',
    description: '',
    defaultMessage:
      'You have made changes to your connection information and therefor need to disconnect your current connection and check for a new connection.',
  },
  check_file: {
    id: 'cargoenv.vehicles.check_file',
    description: '',
    defaultMessage: 'Check File',
  },
  check_vin: {
    id: 'cargoenv.vehicles.check_vin',
    description: '',
    defaultMessage: 'Check VIN',
  },
  check_vin_error: {
    id: 'cargoenv.vehicles.check_vin_error',
    description: '',
    defaultMessage: 'Could not get vehicle data from VIN number',
  },
  choose_vehicle_type: {
    id: 'cargoenv.vehicles.choose_vehicle_type',
    description: '',
    defaultMessage: 'Choose vehicle type',
  },
  choose_vehicle_type_text: {
    id: 'cargoenv.vehicles.choose_vehicle_type_text',
    description: '',
    defaultMessage: 'Choose what type of vehicle you want to add',
  },
  coal_and_oil: {
    id: 'cargoenv.vehicles.coal_and_oil',
    description: '',
    defaultMessage: 'Coal and oil',
  },
  connect_daimler_fleetboard: {
    id: 'cargoenv.vehicles.connect_daimler_fleetboard',
    description: '',
    defaultMessage: 'Connect vehicle via Daimler Fleetboard',
  },
  connect_daimler_fleetboard_text: {
    id: 'cargoenv.vehicles.connect_daimler_fleetboard_text',
    description: '',
    defaultMessage: 'We will be able to connect to your vehicle through a Daimler Fleetboard account.',
  },
  connect_rfms: {
    id: 'cargoenv.vehicles.connect_rfms',
    description: '',
    defaultMessage: 'Connect vehicle via RFMS',
  },
  connect_rfms_text: {
    id: 'cargoenv.vehicles.connect_rfms_text',
    description: '',
    defaultMessage: 'We will be able to connect to your vehicle through a RFMS account.',
  },
  connect_to_unit: {
    id: 'cargoenv.vehicles.connect_to_unit',
    description: '',
    defaultMessage: 'Connect to unit',
  },
  connect_to_unit_text: {
    id: 'cargoenv.vehicles.connect_to_unit_text',
    description: '',
    defaultMessage:
      'Choose this if you have received a telematic unit (Geotab) to plug in to your diagnostics port in your vehicle.',
  },
  connect_vehicle: {
    id: 'cargoenv.vehicles.connect_vehicle',
    description: '',
    defaultMessage: 'Connect vehicle',
  },
  connect_vehicle_toast_info: {
    id: 'cargoenv.vehicles.connect_vehicle_toast_info',
    description: '',
    defaultMessage: 'Connecting vehicle, this may take a moment.',
  },
  connect_your_unit: {
    id: 'cargoenv.vehicles.connect_your_unit',
    description: '',
    defaultMessage: 'Connect your telematic unit',
  },
  connect_your_unit_text: {
    id: 'cargoenv.vehicles.connect_your_unit_text',
    description: '',
    defaultMessage:
      'Please use the provided dongle id and make sure that the VIN and registration number matches the vehicle you want to add.',
  },
  connected: {
    id: 'cargoenv.vehicles.connected',
    description: '',
    defaultMessage: 'Connected',
  },
  connected_text: {
    id: 'cargoenv.vehicles.connected_text',
    description: '',
    defaultMessage: 'This information is needed to be able to connect your vehicle',
  },
  connection: {
    id: 'cargoenv.vehicles.connection',
    description: '',
    defaultMessage: 'Connection',
  },
  connection_suggestion: {
    id: 'cargoenv.vehicles.connection_suggestion',
    description: '',
    defaultMessage: 'Connection suggestion',
  },
  consumption: {
    id: 'cargoenv.vehicles.consumption',
    description: '',
    defaultMessage: 'Consumption',
  },
  could_not_find_any_appropriate_connection_proxies: {
    id: 'cargoenv.vehicles.could_not_find_any_appropriate_connection_proxies',
    description: '',
    defaultMessage: 'Could not find any appropriate connection proxies, please verify that the VIN number is correct.',
  },
  create_new_vehicle: {
    id: 'cargoenv.vehicles.create_new_vehicle',
    description: '',
    defaultMessage: 'Create new vehicle',
  },
  daimler_fleetboard_account: {
    id: 'cargoenv.vehicles.daimler_fleetboard_account',
    description: '',
    defaultMessage: 'Daimler Fleetboard account',
  },
  deactivate: {
    id: 'cargoenv.vehicles.deactivate',
    description: '',
    defaultMessage: 'Deactivate',
  },
  deactivate_vehicle: {
    id: 'cargoenv.vehicles.deactivate_vehicle',
    description: '',
    defaultMessage: 'Deactivate vehicle',
  },
  deactivate_vehicle_dialog_text: {
    id: 'cargoenv.vehicles.deactivate_vehicle_dialog_text',
    description: '',
    defaultMessage:
      'You are about to deactivate the connection to this vehicle. You will no longer receive the transport data from the vehicle. You need to activate the connection to the vehicle if you want to receive transport data. ',
  },
  deactivate_vehicle_dialog_title: {
    id: 'cargoenv.vehicles.deactivate_vehicle_dialog_title',
    description: '',
    defaultMessage: 'Deactivate connection',
  },
  deactivate_vehicle_title_header: {
    id: 'cargoenv.vehicles.deactivate_vehicle_title_header',
    description: '',
    defaultMessage: 'Do you really want to deactivate the connection to this vehicle?',
  },
  deleted_vehicle: {
    id: 'cargoenv.vehicles.deleted_vehicle',
    description: '',
    defaultMessage: 'Successfully deleted {count, plural, one {vehicle} other {# vehicles}}',
  },
  disconnect_and_check_connection: {
    id: 'cargoenv.vehicles.disconnect_and_check_connection',
    description: '',
    defaultMessage: 'Disconnect and check for a new connection!',
  },
  dongle_id: {
    id: 'cargoenv.vehicles.dongle_id',
    description: '',
    defaultMessage: 'Dongle id',
  },
  driveline: {
    id: 'cargoenv.vehicles.driveline',
    description: '',
    defaultMessage: 'Driveline',
  },
  edit_vehicle: {
    id: 'cargoenv.vehicles.edit_vehicle',
    description: '',
    defaultMessage: 'Edit vehicle',
  },
  energy_mix: {
    id: 'cargoenv.vehicles.energy_mix',
    description: '',
    defaultMessage: 'Energy Mix',
  },
  enter_gross_vehicle_weight: {
    id: 'cargoenv.vehicles.enter_gross_vehicle_weight',
    description: '',
    defaultMessage: 'Please enter a gross vehicle weight',
  },
  enter_registration_number: {
    id: 'cargoenv.vehicles.enter_registration_number',
    description: '',
    defaultMessage: 'Please enter the registration number',
  },
  enter_vin: {
    id: 'cargoenv.vehicles.enter_vin',
    description: '',
    defaultMessage: 'Enter VIN',
  },
  enter_vin_number: {
    id: 'cargoenv.vehicles.enter_vin_number',
    description: '',
    defaultMessage: 'Please enter a VIN number',
  },
  environmental_class: {
    id: 'cargoenv.vehicles.environmental_class',
    description: '',
    defaultMessage: 'Environmental class',
  },
  no_environmental_classes: {
    id: 'cargoenv.vehicles.environmental_classes',
    description: '',
    defaultMessage: 'No environmental classes available',
  },
  error_connecting_vehicle: {
    id: 'cargoenv.vehicles.error_connecting_vehicle',
    description: '',
    defaultMessage: 'Failed to connect vehicle',
  },
  error_deleting_org_account: {
    id: 'cargoenv.vehicles.error_deleting_org_account',
    description: '',
    defaultMessage: 'Failed to delete organization account',
  },
  error_deleting_vehicle: {
    id: 'cargoenv.vehicles.error_deleting_vehicle',
    description: '',
    defaultMessage: 'Failed to delete {count, plural, one {vehicle: } other {# vehicles: }} ',
  },
  error_fetching_connection_suggestions: {
    id: 'cargoenv.vehicles.error_fetching_connection_suggestions',
    description: '',
    defaultMessage: 'Failed to fetch connection suggestions',
  },
  error_fetching_org_accounts: {
    id: 'cargoenv.vehicles.error_fetching_org_accounts',
    description: '',
    defaultMessage: 'Failed to fetch organization accounts',
  },
  error_fetching_vehicle: {
    id: 'cargoenv.vehicles.error_fetching_vehicle',
    description: '',
    defaultMessage: 'Failed to fetch vehicle.',
  },
  error_fetching_vehicles: {
    id: 'cargoenv.vehicles.error_fetching_vehicles',
    description: '',
    defaultMessage: 'Failed to fetch vehicles.',
  },
  error_file_upload: {
    id: 'cargoenv.vehicles.error_file_upload',
    description: '',
    defaultMessage: 'Failed to upload the file.',
  },
  error_getting_rfms_fleet_connection: {
    id: 'cargoenv.vehicles.error_getting_rfms_fleet_connection',
    description: '',
    defaultMessage: 'Failed to get RFMS fleet connection',
  },
  error_getting_rfms_supplier: {
    id: 'cargoenv.vehicles.error_getting_rfms_supplier',
    description: '',
    defaultMessage: 'Failed to get a RFMS supplier, please contact administrator to set up a RFMS Supplier.',
  },
  error_getting_rfms_supplier_title: {
    id: 'cargoenv.vehicles.error_getting_rfms_supplier_title',
    description: '',
    defaultMessage: 'RFMS Supplier not configured correctly',
  },
  error_granting_access_to_vehicle: {
    id: 'cargoenv.vehicles.error_granting_access_to_vehicle',
    description: '',
    defaultMessage: 'Failed to grant access to vehicle',
  },
  error_no_supplier_or_org: {
    id: 'cargoenv.vehicles.error_no_supplier_or_org',
    description: '',
    defaultMessage: 'No supplier or active organization found.',
  },
  error_saving_vehicle: {
    id: 'cargoenv.vehicles.error_saving_vehicle',
    description: '',
    defaultMessage: 'Failed to save vehicle.',
  },
  error_saving_vehicle_already_exists: {
    id: 'cargoenv.vehicles.error_saving_vehicle_already_exists',
    description: '',
    defaultMessage: 'Vehicle with that registration number already exists',
  },
  error_storing_org_account: {
    id: 'cargoenv.vehicles.error_storing_org_account',
    description: '',
    defaultMessage: 'Failed to store organization account',
  },
  error_storing_vehicle: {
    id: 'cargoenv.vehicles.error_storing_vehicle',
    description: '',
    defaultMessage: 'Failed to store vehicle',
  },
  error_subscribing_to_vehicle_data: {
    id: 'cargoenv.vehicles.error_subscribing_to_vehicle_data',
    description: '',
    defaultMessage: 'Failed to subscribe to vehicle data',
  },
  error_vehicle_not_eligible: {
    id: 'cargoenv.vehicles.error_vehicle_not_eligible',
    description: '',
    defaultMessage: 'This vehicle is not eligible for connection',
  },
  estimated: {
    id: 'cargoenv.vehicles.estimated',
    description: '',
    defaultMessage: 'Estimated',
  },
  estimated_profile: {
    id: 'cargoenv.vehicles.estimated_profile',
    description: '',
    defaultMessage: 'Estimated profile',
  },
  estimated_text: {
    id: 'cargoenv.vehicles.estimated_text',
    description: '',
    defaultMessage: 'This information is needed to create a estimated profile for your vehicle.',
  },
  estimation_profile: {
    id: 'cargoenv.vehicles.estimation_profile',
    description: '',
    defaultMessage: 'Estimation profile',
  },
  finalize: {
    id: 'cargoenv.vehicles.finalize',
    description: '',
    defaultMessage: 'Finalize',
  },
  fleet_connection_title: {
    id: 'cargoenv.vehicles.fleet_connection_title',
    description: '',
    defaultMessage: 'Create a fleet connection',
  },
  fuel_categories: {
    id: 'cargoenv.vehicles.fuel_categories',
    description: '',
    defaultMessage: 'Fuel Categories',
  },
  fuel_type: {
    id: 'cargoenv.vehicles.fuel_type',
    description: '',
    defaultMessage: 'Fuel type',
  },
  general: {
    id: 'cargoenv.vehicles.general',
    description: '',
    defaultMessage: 'General',
  },
  gross_vehicle_weight: {
    id: 'cargoenv.vehicles.gross_vehicle_weight',
    description: '',
    defaultMessage: 'Gross vehicle weight',
  },
  gross_vehicle_weight_kg: {
    id: 'cargoenv.vehicles.gross_vehicle_weight_kg',
    description: '',
    defaultMessage: 'Gross vehicle weight (kg)',
  },
  high_mobility_text: {
    id: 'cargoenv.vehicles.high_mobility_text',
    description: '',
    defaultMessage: 'It seems that you will be able to connect this vehicle through the Teralo service. ',
  },
  hydro_and_wind: {
    id: 'cargoenv.vehicles.hydro_and_wind',
    description: '',
    defaultMessage: 'Hydro and wind',
  },
  import_finished_info: {
    id: 'cargoenv.vehicles.import_finished_info',
    description: '',
    defaultMessage: 'Make sure to complete your connections to each vehicle to make it work correctly',
  },
  import_pick_headers: {
    id: 'cargoenv.vehicles.import_pick_headers',
    description: '',
    defaultMessage:
      'This are the headlines we found in your uploaded file. Please adjust the headlines in the select list so that it aligns with the headlines in this form.',
  },
  loading_vehicle: {
    id: 'cargoenv.vehicles.loading_vehicle',
    description: '',
    defaultMessage: 'Loading vehicle...',
  },
  loading_vehicles: {
    id: 'cargoenv.vehicles.loading_vehicles',
    description: '',
    defaultMessage: 'Loading vehicles...',
  },
  missing: {
    id: 'cargoenv.vehicles.missing',
    description: '',
    defaultMessage: '{field} missing',
  },
  missing_information: {
    id: 'cargoenv.vehicles.missing_information',
    description: '',
    defaultMessage: 'Missing information',
  },
  missing_information_text: {
    id: 'cargoenv.vehicles.missing_information_text',
    description: '',
    defaultMessage:
      'You have missing information and we will not be able to give you a connection suggestion. Your vehicle will be saved as an estimated vehicle and you need to come back and complete your vehicle information for a proper connection.',
  },
  model_year: {
    id: 'cargoenv.vehicles.model_year',
    description: '',
    defaultMessage: 'Model year',
  },
  new_vehicle: {
    id: 'cargoenv.vehicles.new_vehicle',
    description: '',
    defaultMessage: 'New vehicle',
  },
  no_fuel_types: {
    id: 'cargoenv.vehicles.no_fuel_types',
    description: '',
    defaultMessage: 'No fuel types available',
  },
  no_suitable_connection: {
    id: 'cargoenv.vehicles.no_suitable_connection',
    description: '',
    defaultMessage: 'We could not find a suitable connection to your vehicle.',
  },
  no_suitable_connection_text: {
    id: 'cargoenv.vehicles.no_suitable_connection_text',
    description: '',
    defaultMessage:
      'It seems that we are not able to find an over the air connection with your vehicle. We recommend you to use a telematic unit to plug in to the diagnostics port in your vehicle.',
  },
  no_vehicle_brands: {
    id: 'cargoenv.vehicles.no_vehicle_brands',
    description: '',
    defaultMessage: 'No vehicle brands available',
  },
  odometer: {
    id: 'cargoenv.vehicles.odometer',
    description: '',
    defaultMessage: 'Odometer',
  },
  remove_vehicles: {
    id: 'cargoenv.vehicles.remove_vehicles',
    description: '',
    defaultMessage: 'Remove {count, plural, one {vehicle} other {vehicles} }',
  },
  remove_vehicles_dialog: {
    id: 'cargoenv.vehicles.remove_vehicles_dialog',
    description: '',
    defaultMessage:
      'You are about to remove the connection to the selected {count, plural, one {vehicle} other {vehicles} }. You will no longer receive the transport data from {count, plural, one {this vehicle} other {these vehicles} }. The previous transport data will not be removed, but you will not be able to filter on that specific vehicle. You need to make a new connection for the {count, plural, one {vehicle} other {vehicles} } if you want to receive new transport data.',
  },
  remove_vehicles_title: {
    id: 'cargoenv.vehicles.remove_vehicles_title',
    description: '',
    defaultMessage: 'Do you really want to remove the selected {count, plural, one {vehicle} other {vehicles} }',
  },
  clientId: {
    id: 'cargoenv.vehicles.rfms_client_id',
    description: '',
    defaultMessage: 'Client id',
  },
  rfms_secret: {
    id: 'cargoenv.vehicles.rfms_secret',
    description: '',
    defaultMessage: 'Secret',
  },
  rigid_truck: {
    id: 'cargoenv.vehicles.rigid_truck',
    description: '',
    defaultMessage: 'Rigid truck',
  },
  rigid_truck_text: {
    id: 'cargoenv.vehicles.rigid_truck_text',
    description: '',
    defaultMessage: 'Rigid cargo body fixed on the axle',
  },
  save_and_check_connection: {
    id: 'cargoenv.vehicles.save_and_check_connection',
    description: '',
    defaultMessage: 'Save & Check connection',
  },
  save_as_estimate: {
    id: 'cargoenv.vehicles.save_as_estimate',
    description: '',
    defaultMessage: 'Save as an estimated vehicle?',
  },
  save_vehicle_update: {
    id: 'cargoenv.vehicles.save_vehicle_update',
    description: '',
    defaultMessage: 'Save vehicle update',
  },
  save_vehicle_update_dialog: {
    id: 'cargoenv.vehicles.save_vehicle_update_dialog',
    description: '',
    defaultMessage: 'You have made changes to the vehicle information, are you sure you want to continue?',
  },
  save_vehicle_update_header: {
    id: 'cargoenv.vehicles.save_vehicle_update_header',
    description: '',
    defaultMessage: 'Do you want to save your updates?',
  },
  saved_vehicle: {
    id: 'cargoenv.vehicles.saved_vehicle',
    description: '',
    defaultMessage: 'Successfully saved vehicle',
  },
  saved_vehicle_changes: {
    id: 'cargoenv.vehicles.saved_vehicle_changes',
    description: '',
    defaultMessage: 'Successfully saved vehicle changes',
  },
  scania_account: {
    id: 'cargoenv.vehicles.scania_account',
    description: '',
    defaultMessage: 'Scania account',
  },
  select_brand: {
    id: 'cargoenv.vehicles.select_brand',
    description: '',
    defaultMessage: 'Please select a brand',
  },
  select_fuel_quality_type: {
    id: 'cargoenv.vehicles.select_fuel_quality_type',
    description: '',
    defaultMessage: 'Please select a fuel quality type',
  },
  select_fuel_type: {
    id: 'cargoenv.vehicles.select_fuel_type',
    description: '',
    defaultMessage: 'Please select a fuel type',
  },
  select_vehicle_type: {
    id: 'cargoenv.vehicles.select_vehicle_type',
    description: '',
    defaultMessage: 'Please select a vehicle type',
  },
  setup_needed: {
    id: 'cargoenv.vehicles.setup_needed',
    description: '',
    defaultMessage: 'Setup needed',
  },
  single_vehicle: {
    id: 'cargoenv.vehicles.single_vehicle',
    description: '',
    defaultMessage: 'Single vehicle',
  },
  single_vehicle_text: {
    id: 'cargoenv.vehicles.single_vehicle_text',
    description: '',
    defaultMessage: "Choose this option if you only want to add a single vehicle by it's VIN number.",
  },
  skip_step: {
    id: 'cargoenv.vehicles.skip_step',
    description: '',
    defaultMessage: 'Skip this step',
  },
  tank_capacity: {
    id: 'cargoenv.vehicles.tank_capacity',
    description: '',
    defaultMessage: 'Tank capacity',
  },
  telematic_information: {
    id: 'cargoenv.vehicles.telematic_information',
    description: '',
    defaultMessage: 'Telematic information',
  },
  telematic_status: {
    id: 'cargoenv.vehicles.telematic_status',
    description: '',
    defaultMessage: 'Connection status',
  },
  train_form_text: {
    id: 'cargoenv.vehicles.train_form_text',
    description: '',
    defaultMessage:
      'Please complete the information about your vehicle. The information is used to estimate the energy consumption and calculate greenhouse gas emissions.',
  },
  train_information: {
    id: 'cargoenv.vehicles.train_information',
    description: '',
    defaultMessage: 'Train information',
  },
  train_text: {
    id: 'cargoenv.vehicles.train_text',
    description: '',
    defaultMessage: 'Rail freight transport',
  },
  unsaved_vehicle_changes: {
    id: 'cargoenv.vehicles.unsaved_changes',
    description: '',
    defaultMessage: 'Your vehicle has unsaved changes. If you want to keep your changes, select save and close.',
  },
  unsaved_changes_edit: {
    id: 'cargoenv.vehicles.unsaved_changes_edit',
    description: '',
    defaultMessage: 'You have made changes to the vehicle information, are you sure you want to continue?',
  },
  unsaved_changes_header: {
    id: 'cargoenv.vehicles.unsaved_changes_header',
    description: '',
    defaultMessage: 'You have unsaved changes',
  },
  unsaved_changes_title: {
    id: 'cargoenv.vehicles.unsaved_changes_title',
    description: '',
    defaultMessage: 'Unsaved changes',
  },
  use_as_estimate: {
    id: 'cargoenv.vehicles.use_as_estimate',
    description: '',
    defaultMessage: 'Use as estimate',
  },
  use_as_estimate_confirm: {
    id: 'cargoenv.vehicles.use_as_estimate_confirm',
    description: '',
    defaultMessage: 'Yes, use estimated vehicle',
  },
  use_as_estimate_text: {
    id: 'cargoenv.vehicles.use_as_estimate_text',
    description: '',
    defaultMessage: "My vehicle doesn't support a telematic unit in the diagnostics port.",
  },
  van_text: {
    id: 'cargoenv.vehicles.van_text',
    description: '',
    defaultMessage: 'Road vehicle used for transporting goods',
  },
  vehicle_added: {
    id: 'cargoenv.vehicles.vehicle_added',
    description: '',
    defaultMessage: 'Vehicle added',
  },
  vehicle_added_estimate: {
    id: 'cargoenv.vehicles.vehicle_added_estimate',
    description: '',
    defaultMessage:
      'Your vehicle is now added to your list of vehicles that can be used in transports. Make sure to complete your connection if you want the vehicle to deliver real data, not estimates.',
  },
  vehicle_added_info: {
    id: 'cargoenv.vehicles.vehicle_added_info',
    description: '',
    defaultMessage: 'Your vehicle is now added to your list of vehicles that can be used in transports.',
  },
  vehicle_brand: {
    id: 'cargoenv.vehicles.vehicle_brand',
    description: '',
    defaultMessage: 'Brand',
  },
  vehicle_connected: {
    id: 'cargoenv.vehicles.vehicle_connected',
    description: '',
    defaultMessage: 'Vehicle is connected',
  },
  vehicle_connected_successfully: {
    id: 'cargoenv.vehicles.vehicle_connected_successfully',
    description: '',
    defaultMessage: 'Vehicle connected successfully',
  },
  vehicle_deactivated: {
    id: 'cargoenv.vehicles.vehicle_deactivated',
    description: '',
    defaultMessage: 'Vehicle succesfully deactivated',
  },
  vehicle_form_info: {
    id: 'cargoenv.vehicles.vehicle_form_info',
    description: '',
    defaultMessage:
      'Please complete the information about your vehicle. The information is used to connect your vehicle so that Teralo can track the energy consumption and calculate greenhouse gas emissions.',
  },
  vehicle_information: {
    id: 'cargoenv.vehicles.vehicle_information',
    description: '',
    defaultMessage: 'Vehicle information',
  },
  vehicle_model: {
    id: 'cargoenv.vehicles.vehicle_model',
    description: '',
    defaultMessage: 'Vehicle model',
  },
  vehicle_odometer: {
    id: 'cargoenv.vehicles.vehicle_odometer',
    description: '',
    defaultMessage: "Vehicle's odometer",
  },
  vehicle_tank_capacity: {
    id: 'cargoenv.vehicles.vehicle_tank_capacity',
    description: '',
    defaultMessage: "Vehicle's tank capacity",
  },
  vehicle_type: {
    id: 'cargoenv.vehicles.vehicle_type',
    description: '',
    defaultMessage: 'Vehicle type',
  },
  vehicles_added: {
    id: 'cargoenv.vehicles.vehicles_added',
    description: '',
    defaultMessage: '{count, plural, one {# vehicle} other {# vehicles}} added',
  },
  view_vehicle: {
    id: 'cargoenv.vehicles.view_vehicle',
    description: '',
    defaultMessage: 'View vehicle',
  },
  view_my_vehicles: {
    id: 'cargoenv.vehicles.view_vehicles',
    description: '',
    defaultMessage: 'View my vehicles',
  },
  vin_change_alert: {
    id: 'cargoenv.vehicles.vin_change_alert',
    description: '',
    defaultMessage:
      'If you change the VIN you will need to do a new connection check as your current connection might no longer be valid.',
  },
  vin_message: {
    id: 'cargoenv.vehicles.vin_message',
    description: '',
    defaultMessage:
      'Enter your vehicle identification number (VIN) below to start setting up your vehicle. Skip this step if you dont have access to you VIN.',
  },
  vin_number: {
    id: 'cargoenv.vehicles.vin_number',
    description: '',
    defaultMessage: 'VIN Number',
  },
  warning_remove_vehicles: {
    id: 'cargoenv.vehicles.warning_remove_vehicles',
    description: '',
    defaultMessage: 'Unable to remove vehicles',
  },
  warning_remove_vehicles_dialog: {
    id: 'cargoenv.vehicles.warning_remove_vehicles_dialog',
    description: '',
    defaultMessage:
      'We could not remove {count, plural, one {# vehicle} other {# vehicles} } because of following reasons:',
  },
  warning_remove_vehicles_title: {
    id: 'cargoenv.vehicles.warning_remove_vehicles_title',
    description: '',
    defaultMessage: '{count, plural, one {# vehicle was not removed} other {# vehicles were not removed} }',
  },
  wireless_connection_available: {
    id: 'cargoenv.vehicles.wireless_connection_available',
    description: '',
    defaultMessage: 'Wireless connection available',
  },
});
