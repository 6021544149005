import React, { useContext, useState } from 'react';
import {
  Body,
  Footer,
  Header,
  HeaderTitle,
  Icon,
  IconButton,
  MenuItem,
  Navigation,
  Typography,
  View,
  Views,
} from '@ccsdk/kingbolt';
import Drawer from '@mui/material/Drawer';
import styled from '@emotion/styled';
import { routesMap, TeraloRoute } from '../PageRoutes/routes';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { useAuth } from '../../hooks/auth/useAuth';
import messages from '../../messages';
import UserData from './UserData';
import SwitchOrganisationsView from './SwitchOrganisationsView';
import { Link } from 'react-router-dom';
import { SofApplicationContext } from '../../context/SofApplicationContext/SofApplicationContext';
import { Organization, useStoreUserContextMutation } from '../../generated/sof-graphql';
import { AuthContext } from '../../context/AuthContext';
import { ToastContext, ToastLevel } from '../../context/ToastContext/ToastContext';

interface Props {
  open: boolean;
  toggleUserMenu: () => void;
}

const UserMenu: React.FC<Props> = ({ open, toggleUserMenu }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const { user } = useContext(AuthContext);
  const { openToast } = useContext(ToastContext);

  const { state, clearApplicationContext, setActiveOrgAndOrgRoles } = useContext(SofApplicationContext);
  const [selectedView, setSelectedView] = useState<number>(0);
  const [storeUserContext, { loading: storeUserContextLoading, error: storeUserContextError }] =
    useStoreUserContextMutation();

  const token: string = (user as any)?.signInUserSession?.idToken?.jwtToken;

  const onSignOutClick = async () => {
    await storeUserContext({ variables: {} });
    await signOut();
    toggleUserMenu();
    clearApplicationContext();
    navigate(routesMap[TeraloRoute.LOGIN]);
  };

  const onOrganisationClick = (organization: Organization | null | undefined) => async () => {
    if (organization) {
      await storeUserContext({
        variables: { activeOrgId: organization.id },
        onCompleted: async (res) => {
          if (res.storeUserContext) {
            const result = await setActiveOrgAndOrgRoles(organization.id, token);

            if (result) {
              navigate(0);
            }
          }
        },
        onError: (error) => {
          openToast(`${formatMessage(messages.error_switch_organisation)}: ${error}`, ToastLevel.ERROR);
        },
      });
    }
  };

  const goToView = (index: number) => () => {
    setSelectedView(index);
  };

  if (storeUserContextError) {
    openToast(
      `${formatMessage(messages.error_storing_user_context)}: ${storeUserContextError.message}`,
      ToastLevel.ERROR,
    );
  }

  return (
    <AppDrawer anchor="right" variant="persistent" id="user-menu" open={open}>
      <Views index={selectedView} stretch>
        <UserMenuView id="user-menu" jump>
          <Navigation>
            <Header>
              <HeaderTitle>{formatMessage(messages.user_menu)}</HeaderTitle>
              <IconButton onClick={toggleUserMenu}>
                <Icon type="close" />
              </IconButton>
            </Header>
            <Body>
              <UserData />
              {state.organizations.length > 1 && (
                <>
                  <Subtitle variant="subtitle2">{formatMessage(messages.organisation)}</Subtitle>

                  <UserMenuItem onClick={goToView(1)}>
                    <Icon type="fleets" />
                    {formatMessage(messages.switch_organisation)}
                  </UserMenuItem>
                  <UserMenuItem component={Link} to={'/admin/users'}>
                    <Icon type="user" />
                    {formatMessage(messages.users)}
                  </UserMenuItem>
                </>
              )}
            </Body>
            <UserMenuFooter>
              <SignOutButton onClick={onSignOutClick}>
                <Icon type="logout" />
                <Typography variant="button">{formatMessage(messages.sign_out)}</Typography>
              </SignOutButton>
            </UserMenuFooter>
          </Navigation>
        </UserMenuView>
        <UserMenuView id="switch-organization">
          <SwitchOrganisationsView
            organisations={state.organizations}
            goToView={goToView}
            loading={storeUserContextLoading}
            onOrganisationClick={onOrganisationClick}
          />
        </UserMenuView>
      </Views>
    </AppDrawer>
  );
};

const AppDrawer = styled(Drawer)`
  .MuiPaper-root {
    box-sizing: border-box;
    width: 100vw;
    top: 49px;
    flex-shrink: 0;
    z-index: 3;

    @media (min-width: ${(props: any) => props.theme.breakpoints.values.sm}px) {
      width: 320px;
      top: 4rem;
    }
  }
`;

const Subtitle = styled(Typography)`
  padding: 1rem;
`;

const UserMenuItem = styled(MenuItem)`
  svg {
    margin-right: 0.5rem;
  }
`;

const UserMenuFooter = styled(Footer)`
  padding: 0 1rem 1rem 0;
  margin: 0 0 4rem 0;
`;

const SignOutButton = styled(IconButton)`
  width: initial;

  svg {
    margin-right: 0.5rem;
  }
`;

const UserMenuView = styled(View)`
  overflow: hidden;
`;

export default UserMenu;
