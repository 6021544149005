import React, { createContext, FunctionComponent, useContext, useMemo, useState } from 'react';
import { FiltersInput } from '../../components/Reports/types';
import { TransportOrderReportRows, TransportOrderSummaryReport } from '../../generated/sof-graphql';

const defaultContextValue = {
  contextFilters: null,
  contextOrderReportRows: null,
  contextOrderReportSummary: null,
  setContextFilters: () => {},
  setContextOrderReportRows: () => {},
  setContextOrderReportSummary: () => {},
};

interface TransportOrderReportsContextInterface {
  contextFilters: FiltersInput | null;
  contextOrderReportRows: TransportOrderReportRows | null;
  contextOrderReportSummary: TransportOrderSummaryReport | null;
  setContextFilters(filters: FiltersInput): void;
  setContextOrderReportRows(orderReportRows: TransportOrderReportRows): void;
  setContextOrderReportSummary(orderReportSummary: TransportOrderSummaryReport): void;
}

export const TransportOrderReportsContext = createContext<TransportOrderReportsContextInterface>(defaultContextValue);

const useTransportOrderReport = () => {
  const context = useContext(TransportOrderReportsContext);
  if (!context) {
    throw new Error('useTransportOrderReportsContext must be used within a ToastContext');
  }
  return context;
};

interface Props {
  children: React.ReactNode;
}

const TransportOrderReportsContextProvider: FunctionComponent<Props> = ({ children }) => {
  const [contextFilters, setFilters] = useState<FiltersInput | null>(defaultContextValue.contextFilters);
  const [contextOrderReportRows, setOrderReportRows] = useState<TransportOrderReportRows | null>(
    defaultContextValue.contextOrderReportRows,
  );
  const [contextOrderReportSummary, setOrderReportSummary] = useState<TransportOrderSummaryReport | null>(
    defaultContextValue.contextOrderReportSummary,
  );

  const setContextFilters = (filters: FiltersInput) => {
    setFilters(filters);
  };

  const setContextOrderReportRows = (orderReport: TransportOrderReportRows) => {
    setOrderReportRows(orderReport);
  };

  const setContextOrderReportSummary = (orderReport: TransportOrderSummaryReport) => {
    setOrderReportSummary(orderReport);
  };

  const value = useMemo(
    () => ({
      contextFilters,
      setContextFilters,
      contextOrderReportRows,
      setContextOrderReportRows,
      contextOrderReportSummary,
      setContextOrderReportSummary,
      useTransportOrderReport,
    }),
    [contextFilters, contextOrderReportRows, contextOrderReportSummary],
  );

  return <TransportOrderReportsContext.Provider value={value}>{children}</TransportOrderReportsContext.Provider>;
};

export { TransportOrderReportsContextProvider, useTransportOrderReport };
